import { useEffect, ReactElement } from "react";
import { useLocation } from "react-router";
const ScrollToTop = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  useEffect(() => {
    document.getElementById("page-content")?.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default ScrollToTop;
