import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  getCxmPetsDetailId,
  patchCxmPetsId,
  getCxmPetsMetadataBreeds,
  getCxmPetsMetadataActivityCodes,
  getCxmPetsMetadataLifestyles,
  getCxmPetsMetadataSensitivities,
  getCxmPetsMetadataHair,
  getCxmPetsMetadataPurchasePlacesPreference,
  patchCxmPetsIdPhoto,
} from "api/services";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import { PatchPetDTO, PetDetailVo, PetMetadataVo } from "api/types";
import moment from "moment";
import { LeftOutlined } from "@ant-design/icons";
const { Title } = Typography;

const Edit = () => {
  const [form] = Form.useForm();
  const { i18n } = useTranslation("common");
  const [initialValues, setInitialValues] = useState<
    Omit<PetDetailVo, "photo"> & { photo: UploadFile[] } & {
      birthdate: moment.Moment;
    }
  >();
  const [image, setImage] = useState<UploadFile[]>([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [activityCodes, setActivityCodes] = useState<string[]>([]);
  const [breeds, setBreeds] = useState<PetMetadataVo[]>([]);
  const [lifestyles, setLifestyles] = useState<string[]>([]);
  const [sensitivities, setSensitivities] = useState<string[]>([]);
  const [purchasePlacePrefs, setPurchasePlacePrefs] = useState<string[]>([]);
  const [hairs, setHairs] = useState<string[]>([]);
  const { id, petId } = useParams<{ id: string; petId: string }>();
  const { t } = useTranslation("customers_list");
  const handleOk = async () => {
    form
      .validateFields()
      .then(async (values: PatchPetDTO & { birthdate: moment.Moment }) => {
        let newValues = {} as PatchPetDTO;
        Object.keys(values).map(async (value) => {
          switch (value) {
            case "birthdate":
              if (
                moment(values.birthdate).toISOString() !==
                moment(initialValues?.birth?.date).toISOString()
              ) {
                newValues = {
                  ...newValues,
                  birth: {
                    date: moment(values.birthdate).toISOString(),
                    isExact: values.birth?.isExact,
                  },
                };
              }
              break;
            case "sensitivities":
              if (
                [
                  ...(values.sensitivities || []),
                  ...(initialValues?.sensitivities || []),
                ].filter(
                  (element) =>
                    !values?.sensitivities?.includes(element) ||
                    !initialValues?.sensitivities?.includes(element)
                ).length
              ) {
                newValues = {
                  ...newValues,
                  sensitivities: values.sensitivities,
                };
              }
              break;
            case "birth":
              if (values.birth?.isExact !== initialValues?.birth?.isExact) {
                newValues = {
                  ...newValues,
                  birth: {
                    ...newValues.birth,
                    isExact: values.birth?.isExact,
                  },
                };
              }
              break;
            case "photo":
              if ((values.photo as any).fileList?.[0]?.originFileObj) {
                await patchCxmPetsIdPhoto(petId, {
                  photo: (values.photo as any).fileList?.[0]?.originFileObj,
                });
              }
              break;
            default:
              if (
                (values as any)?.[value] !== (initialValues as any)?.[value]
              ) {
                newValues = {
                  ...newValues,
                  [value]: (values as any)[value],
                };
              }
          }
        });
        setIsLoading(true);
        await patchCxmPetsId(petId, newValues);
        setIsLoading(false);
        history.push(`/app/customer/list/detail/${id}`);
        return;
      })
      .catch((info) => {
        setIsLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  const getPetData = async () => {
    const data = await getCxmPetsDetailId(petId);
    data?.photo && setImage([{ thumbUrl: data?.photo } as UploadFile]);
    await getBreed(data.speciesCode);
    setInitialValues({
      ...data,
      photo: [],
      birthdate: moment(data?.birth?.date) || moment(),
    });
  };

  const getBreed = async (speciesCode?: string) => {
    const data = await getCxmPetsMetadataBreeds({
      speciesCode,
    });
    setBreeds(data);
  };

  const getActivityCode = async () => {
    const data = await getCxmPetsMetadataActivityCodes();
    setActivityCodes(data);
  };

  const getLifestyles = async () => {
    const data = await getCxmPetsMetadataLifestyles();
    setLifestyles(data);
  };

  const getSensitivities = async () => {
    const data = await getCxmPetsMetadataSensitivities();
    setSensitivities(data);
  };

  const getPurchasePlacePref = async () => {
    const data = await getCxmPetsMetadataPurchasePlacesPreference();
    setPurchasePlacePrefs(data);
  };

  const getHair = async () => {
    const data = await getCxmPetsMetadataHair();
    setHairs(data);
  };

  const getFileStream = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      file.thumbUrl = e.target!.result;
      file.status = "done";
      setImage([file]);
    };
    return false;
  };

  const getInitialData = async () => {
    await getPetData();
    await getActivityCode();
    await getLifestyles();
    await getSensitivities();
    await getHair();
    await getPurchasePlacePref();
    setIsLoading(false);
  };
  useEffect(() => {
    getInitialData();
  }, []);

  const goBack = () => {
    history.push(`/app/customer/list/detail/${id}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Form form={form} initialValues={initialValues}>
      <div className={styles["pet-edit-header"]}>
        <div className={styles["pet-edit-main"]}>
          <div>
            <div className={styles["pet-edit-infos"]}>
              <span className={styles["pet-edit-title"]}>
                {t("pet_informations_tab.edit_title").toUpperCase()}
              </span>
            </div>
            <div className={styles["pet-edit-name"]}>{initialValues?.name}</div>
          </div>
          <div className={styles.back}>
            <LeftOutlined className={styles["back-icon"]} />
            <span onClick={goBack} className={styles["back-link"]}>
              {t("pet_informations_tab.back")}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <Title level={4}>{t("pet_informations_tab.tab_name")}</Title>
        <Row gutter={40} className={styles.row}>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="name"
              label={t("pet_informations_tab.name")}
            >
              <Input placeholder={t("pet_informations_tab.name")} />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="speciesCode"
              label={t("pet_informations_tab.species")}
            >
              <Input
                disabled={true}
                placeholder={t("pet_informations_tab.species")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="genderCode"
              label={t("pet_informations_tab.gender")}
            >
              <Radio.Group>
                <Radio value={"male"}>Male</Radio>
                <Radio value={"female"}>Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="breedCode"
              label={t("pet_informations_tab.breed_name")}
            >
              <Select
                placeholder={t("pet_informations_tab.breed_name")}
                optionFilterProp="children"
                showSearch
              >
                {breeds.map((breed) => (
                  <Select.Option value={breed.breedCode} key={breed.breedCode}>
                    {breed.breedName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="neutered"
              label={t("pet_informations_tab.neutered_status")}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="birthdate"
              shouldUpdate
              label={t("pet_informations_tab.birth_date")}
            >
              <DatePicker
                placeholder={t("pet_informations_tab.birth_date")}
                className={styles.datepicker}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name={["birth", "isExact"]}
              label={t("pet_informations_tab.is_exact")}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="lifestyle"
              label={t("pet_informations_tab.lifestyle")}
            >
              <Select
                placeholder={t("pet_informations_tab.lifestyle")}
                optionFilterProp="children"
              >
                {lifestyles.map((lifestyle) => (
                  <Select.Option value={lifestyle} key={lifestyle}>
                    {lifestyle}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="petActivityCode"
              label={t("pet_informations_tab.activity_level")}
            >
              <Select
                placeholder={t("pet_informations_tab.activity_level")}
                optionFilterProp="children"
              >
                {activityCodes.map((activity) => (
                  <Select.Option value={activity} key={activity}>
                    {activity}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="microchipId"
              label={t("pet_informations_tab.chip_number")}
            >
              <Input placeholder={t("pet_informations_tab.chip_number")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="tattooId"
              label={t("pet_informations_tab.tatoo_number")}
            >
              <Input placeholder={t("pet_informations_tab.tatoo_number")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="coatColour"
              label={t("pet_informations_tab.coat_colour")}
            >
              <Input placeholder={t("pet_informations_tab.coat_colour")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="hair"
              label={t("pet_informations_tab.hair")}
            >
              <Select
                placeholder={t("pet_informations_tab.hair")}
                optionFilterProp="children"
              >
                {hairs.map((hair) => (
                  <Select.Option value={hair} key={hair}>
                    {hair}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="sensitivities"
              label={t("pet_informations_tab.pet_sensitivities")}
            >
              <Select
                mode="multiple"
                placeholder={t("pet_informations_tab.pet_sensitivities")}
                optionFilterProp="children"
              >
                {sensitivities.map((sensitivity) => (
                  <Select.Option value={sensitivity} key={sensitivity}>
                    {sensitivity}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="purchasePlacePref"
              label={t("pet_informations_tab.preferred_purchase_place")}
            >
              <Select
                placeholder={t("pet_informations_tab.preferred_purchase_place")}
                optionFilterProp="children"
              >
                {purchasePlacePrefs.map((purchasePlacePref) => (
                  <Select.Option
                    value={purchasePlacePref}
                    key={purchasePlacePref}
                  >
                    {purchasePlacePref}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles["file-form"]}>
              <div className={styles["file-input"]}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="photo"
                  label={t("pet_informations_tab.photo")}
                >
                  <Upload
                    showUploadList={false}
                    name="file"
                    multiple
                    fileList={image}
                    maxCount={1}
                    accept=".jpg, .png, .jpeg, .gif"
                    beforeUpload={getFileStream}
                  >
                    <Button type="primary" shape="round">
                      {t("pet_informations_tab.choose_file")}
                    </Button>
                  </Upload>
                </Form.Item>
                <div>
                  {!!image[0] && (
                    <img
                      alt=""
                      className={styles["file-preview"]}
                      src={image[0].thumbUrl}
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={classNames(styles["content"], styles.last)}>
        <Button shape="round" type="primary" ghost onClick={handleOk}>
          {t("pet_informations_tab.save")}
        </Button>
      </div>
    </Form>
  );
};

export default Edit;
