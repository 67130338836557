import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const appName = "cxm";

const config = {
  i18n: {
    defaultLocale: "en",
    locales: ["en", "fr"],
  },
  resources: require(`./${appName}`).default.resources,
  fallbackLng: "en",
  defaultNS: require(`./${appName}`).default.defaultNS,
  ns: require(`./${appName}`).ns,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(config);

export default i18n;
