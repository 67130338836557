import { Layout } from "antd";
import { useToggle } from "ahooks";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import VIcon from "components/VIcon";
import styles from "./styles.module.scss";
import { useState } from "react";
import { ReadOnlyMenuInfo } from "api/types";

interface Route {
  breadcrumbName: string;
  icon: string;
  name: string;
  path: string;
  routes: Route[];
}

interface MenuInfoWithRoutes extends ReadOnlyMenuInfo {
  routes: Route[];
}

const { SubMenu } = Menu;
const { Sider } = Layout;

const LeftSider = ({ menu }: { menu: MenuInfoWithRoutes[] }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const firstLevelRoutePath = pathname.split("/", 3).join("/");
  const secondLevelRoutePath = pathname.split("/", 4).join("/");
  const [collapsed, { toggle }] = useToggle();
  const [openKeys, setOpenKeys] = useState([firstLevelRoutePath]);
  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  if (!menu) return null;
  return (
    <Sider
      className={styles.wrapper}
      collapsible
      trigger={null}
      width={210}
      collapsed={collapsed}
    >
      <div className={styles.container}>
        <Menu
          inlineIndent={12}
          className={styles.menu}
          mode="inline"
          selectedKeys={[firstLevelRoutePath, secondLevelRoutePath]}
          openKeys={openKeys}
          defaultOpenKeys={openKeys}
          onOpenChange={onOpenChange}
          items={menu.map((menuItem) => {
            return {
              key: menuItem.path!,
              label: <span className={styles.title}>{menuItem.name}</span>,
              icon: collapsed && menuItem.icon && (
                <VIcon type={menuItem.icon} className={styles["menu-icon"]} />
              ),
              children: menuItem.routes.map((childMenuItem) => {
                return {
                  key: childMenuItem.path,
                  icon: (
                    <VIcon
                      type={childMenuItem.icon}
                      className={styles["menu-child-icon"]}
                    />
                  ),
                  label: [
                    <Link
                      to={childMenuItem.path}
                      key={childMenuItem.path}
                      onClick={() => {
                        setOpenKeys([
                          childMenuItem.path.split("/", 3).join("/"),
                        ]);
                      }}
                    >
                      {childMenuItem.name}
                    </Link>,
                  ],
                };
              }),
            };
          })}
        />
        <div className={styles["LeftOutlined-box"]} onClick={toggle}>
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </div>
      </div>
    </Sider>
  );
};

export default LeftSider;
