import { Skeleton } from "antd";
import styles from "./styles.module.scss";

export default function CustomizeSkeleton() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Skeleton active />
      </div>
      <div className={styles.container}>
        <Skeleton active />
      </div>
    </div>
  );
}
