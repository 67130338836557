import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const AuthorityManage = lazy(() => import("../pages/setting/authority-manage"));

function SettingRoutes() {
  return (
    <Switch>
      <Redirect path="/app/setting" to="/app/setting/authority-manage" exact />
      <Route
        path="/app/setting/authority-manage"
        component={AuthorityManage}
        exact
      />
    </Switch>
  );
}

export default SettingRoutes;
