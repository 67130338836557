import classNames from "classnames";
import styles from "./styles.module.scss";

export default function LoadingForRC() {
  return (
    <img
      className={classNames(styles.spinner, "spinner", "ant-spin-dot")}
      src="https://wanmi-b2b.oss-cn-shanghai.aliyuncs.com/202011020724162245.gif"
      alt=""
    />
  );
}
