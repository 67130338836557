import { Layout, Spin } from "antd";
import LeftSider from "components/LeftSider";
import Header from "components/Header";
import Footer from "components/Footer";
import CustomizeSkeleton from "components/CustomizeSkeleton";
import { useHistory, useLocation } from "react-router-dom";
import { ReactNode, Suspense, useEffect, useLayoutEffect } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n/init";
import useInit from "../hooks/useInit";
import AccessContext from "contexts/AccessContext";
import styles from "./styles.module.scss";

const { Content } = Layout;
//获取左侧submenu

function AppLayout({ children }: { children: ReactNode }) {
  const history = useHistory();
  const location = useLocation();
  const { loading, menu, accessFunctions } = useInit();
  useLayoutEffect(() => {
    if (!menu && !loading) {
      history.push(`/?redirect=${location.pathname + location.search}`);
    }
  }, []);
  return (
    <AccessContext.Provider
      value={{
        accessFunctions,
      }}
    >
      <I18nextProvider i18n={i18n}>
        <Spin spinning={loading} className={styles.wrapper}>
          {!loading && (
            <Layout>
              <Header />
              <Layout>
                <LeftSider menu={menu} />
                <Content className={styles.container} id="page-content">
                  <div id="rc-spin-container">
                    <Suspense fallback={<CustomizeSkeleton />}>
                      {children}
                    </Suspense>
                  </div>
                  <Footer />
                </Content>
              </Layout>
            </Layout>
          )}
        </Spin>
      </I18nextProvider>
    </AccessContext.Provider>
  );
}

export default AppLayout;
