const const_config = {
  SUCCESS_CODE: "K-000000",

  ICON_SIZE: { fontSize: 20 },

  POPUP_CONTAINER: () => document.getElementById("page-content") as HTMLElement,
  POPUP_CONTAINER_PARENT: (node: any) =>
    node.parent || document.getElementById("app"),
};

export default const_config;
