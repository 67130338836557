const i18nConfig = {
  resources: {
    en: {
      common: require("./en/common.json"),
      partners_list: require("./en/partners_list.json"),
      customers_list: require("./en/customers_list.json"),
      import_files: require("./en/import_files.json"),
      loyalty_b2b: require("./en/loyalty_b2b.json"),
      deals: require("./en/deals.json"),
      login: require("./en/login.json"),
      settings: require("./en/settings.json"),
      404: require("./en/404.json"),
    },
  },
  ns: ["common"],
  defaultNS: "common",
};

export default i18nConfig;
