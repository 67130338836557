import { lazy } from "react";
import { Route, Switch } from "react-router-dom";

const DealDetails = lazy(() => import("../pages/deals/details"));
const DealsTabs = lazy(() => import("../pages/deals"));
const DealsCreate = lazy(() => import("../pages/deals/create"));
const DealsEdit = lazy(() => import("../pages/deals/edit"));

function DealsRoutes() {
  return (
    <Switch>
      <Route path="/app/deals" component={DealsTabs} exact />
      <Route path="/app/deals/create" component={DealsCreate} exact />
      <Route path="/app/deals/:id" component={DealDetails} exact />
      <Route path="/app/deals/:id/edit" component={DealsEdit} exact />
    </Switch>
  );
}

export default DealsRoutes;
