import { Button } from "antd";
import { useOktaAuth } from "@okta/okta-react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const LoginButton = ({ handleLogin }: { handleLogin?: () => void }) => {
  const { t } = useTranslation("common");
  const Okta = useOktaAuth();
  const handleClick = () => {
    handleLogin?.();
    Okta.oktaAuth.signInWithRedirect();
  };
  return (
    <Button type="primary" onClick={handleClick} className={styles.button}>
      {t("login")}
    </Button>
  );
};

export default LoginButton;
