import { useLocation } from "react-router-dom";
/**
 *
 * 获取SearchParams
 */
export function useSearchParams<T extends Record<string, unknown>>(): T {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return Object.fromEntries(searchParams) as T;
}
