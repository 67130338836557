import AppLayout from "layout";
import Login from "pages/auth/login";
import Logout from "pages/auth/logout";
import { Switch, Route, Redirect } from "react-router-dom";
import DealsRoutes from "./deals";
import CustomerRoutes from "./customer";
import LoyaltyB2BRoutes from "./loyalty-b2b";
import LoyaltyRoutes from "./loyalty";
import PartnerRoutes from "./partner";
import SettingRoutes from "./setting";
import ImportFilesRoutes from "./import-files";
import Page404 from "pages/404";
import LoginCallback from "pages/auth/login-callback";
import { useOktaAuth } from "@okta/okta-react";

function Routes() {
  const { authState } = useOktaAuth();
  return (
    <Switch>
      <Route path="/" component={Login} exact />
      <Route path="/logout" component={Logout} exact />
      <Route path="/implicit/callback" component={LoginCallback} />
      <Route path="/app">
        {authState !== null && (
          <AppLayout>
            <DealsRoutes />
            <CustomerRoutes />
            <ImportFilesRoutes />
            <LoyaltyB2BRoutes />
            <LoyaltyRoutes />
            <PartnerRoutes />
            <SettingRoutes />
          </AppLayout>
        )}
      </Route>
      <Route path="/404" component={Page404} />
      <Redirect from="*" to="/404" />
    </Switch>
  );
}

export default Routes;
