import Box from "components/Box";
import Image404 from "assets/images/404.png";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import Header from "components/Header";

function Page404() {
  const { t } = useTranslation("404");
  return (
    <>
      <Header />
      <Box className={styles.container}>
        <img src={Image404} className={styles.not_found_image} />
        <h1>{t("title")}</h1>
        <p className={styles.explanation}>{t("explanation")}</p>
      </Box>
    </>
  );
}

export default Page404;
