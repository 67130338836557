//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from "axios";
import type { SwaggerResponse } from "./config";
import { Http } from "./httpRequest";
//@ts-ignore
import qs from "qs";
import type {
  GetCxmLoyaltyProgramsQueryParams,
  GetCxmPersonalConfigurationQueryParams,
  GetCxmLoyaltyPointsQueryParams,
  GetCxmImportsQueryParams,
  GetCxmDealsQueryParams,
  GetCxmDealsDeal_idQueryParams,
  GetCxmPetsMetadataBreedsQueryParams,
  GetCxmPartnersQueryParams,
  GetCxmPartnerRelationsB2cQueryParams,
  GetCxmPartnerRelationsB2bQueryParams,
  GetCxmLoyaltyB2bPointsQueryParams,
  GetCxmImportsMineQueryParams,
  GetCxmEventsB2cPet_owner_idQueryParams,
  GetCxmDealsDeal_idPartnerCodesQueryParams,
  GetCxmDealsDeal_idCouponsQueryParams,
  GetCxmDealsDeal_idBulksBulk_idDownloadQueryParams,
  GetCxmDealsCodeDeal_codeQueryParams,
  GetCxmCustomersQueryParams,
  GetCxmApplicationsQueryParams,
  SaveScopeDTO,
  SaveMenuInfoDTO,
  LoyaltyProgramAddRequest,
  LoyaltyProgramVo,
  LoyaltyProgramEditEndDateRequest,
  SaveFunctionInfoDTO,
  ScopeDTO,
  SaveAuthorityInfoDTO,
  CreationResponseDTO,
  AuthorityShot,
  PetWeightDTO,
  PetWeightVo,
  SavePersonalConfigDTO,
  B2cRelationCreationResponse,
  SaveB2bRelationDTO,
  Relationship,
  JwtTokenRequest,
  JwtTokenResponse,
  CountryResponse,
  SaveEventDTO,
  LoyaltyPointsAddRequest,
  LoyaltyPointsAddVo,
  LoyaltyB2bPointsAddRequest,
  LoyaltyB2bPointsAddVo,
  DealVo,
  EmailGiftDTO,
  BulkDealCouponDTO,
  TimeZoneDTO,
  CurrentTimeDTO,
  PatchPetDTO,
  PetDetailVo,
  EditEventDTO,
  DealResponse,
  DealCouponDTO,
  DealCouponResponse,
  TemplatesResponse,
  PetMetadataVo,
  PersonalConfigurationDTO,
  PartnerPageResponse,
  PartnerRcSyncInformationQueryResponse,
  RelationshipPageResponse,
  MenuInfoDTO,
  SidebarInfoVo,
  LoyaltyProgramsResponse,
  LoyaltyPointsResponse,
  LoyaltyCumulativePointsVo,
  LoyaltyB2bPointsResponse,
  LoyaltyB2bCumulativePointsVo,
  ImportPageResponse,
  FunctionInfoDTO,
  EventPageResponse,
  DealDetailVo,
  DealPageResponse,
  DealPartnerCodesPage,
  DealCouponsPage,
  OktaUser,
  SyncUserVo,
  CustomerConsentVo,
  AuthorityInfoDTO,
  ApplicationResponse,
  ApplicationVO,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== "production";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Delete authority
 */
export const deleteCxmAuthoritiesAuthority_id = (
  authority_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteCxmAuthoritiesAuthority_id.key, { authority_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteCxmAuthoritiesAuthority_id.key = "/cxm/authorities/{authority_id}";

/**
 *
 * Delete all partner code of a deal
 */
export const deleteCxmDealsDeal_idPartnerCodesAll = (
  deal_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteCxmDealsDeal_idPartnerCodesAll.key, { deal_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteCxmDealsDeal_idPartnerCodesAll.key =
  "/cxm/deals/{deal_id}/partner-codes/all";

/**
 *
 * Delete function
 */
export const deleteCxmFunctionsFunction_id = (
  function_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteCxmFunctionsFunction_id.key, { function_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteCxmFunctionsFunction_id.key = "/cxm/functions/{function_id}";

/**
 *
 * Delete menu
 */
export const deleteCxmMenusMenu_id = (
  menu_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteCxmMenusMenu_id.key, { menu_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteCxmMenusMenu_id.key = "/cxm/menus/{menu_id}";

/**
 *
 * Remove B2B-Relation's Application
 */
export const deleteCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_id =
  (
    partner_id: string,
    okta_id: string,
    client_id: string,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_id.key,
        { partner_id, okta_id, client_id }
      ),
      undefined,
      undefined,
      _CONSTANT1,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
deleteCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_id.key =
  "/cxm/partner-relations/b2b/partners/{partner_id}/users/{okta_id}/applications/{client_id}";

/**
 *
 * Remove B2B-Relation's AppRole
 */
export const deleteCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_idAppRolesRole_name =
  (
    partner_id: string,
    okta_id: string,
    client_id: string,
    role_name: string,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_idAppRolesRole_name.key,
        { partner_id, okta_id, client_id, role_name }
      ),
      undefined,
      undefined,
      _CONSTANT1,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
deleteCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_idAppRolesRole_name.key =
  "/cxm/partner-relations/b2b/partners/{partner_id}/users/{okta_id}/applications/{client_id}/appRoles/{role_name}";

/**
 *
 * Delete scope (Non-consumable by pox-front)
 */
export const deleteCxmScopesScope_id = (
  scope_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteCxmScopesScope_id.key, { scope_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteCxmScopesScope_id.key = "/cxm/scopes/{scope_id}";

/**
 *
 * Application list
 */
export const getCxmApplications = (
  queryParams?: GetCxmApplicationsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ApplicationResponse>> => {
  return Http.getRequest(
    getCxmApplications.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmApplications.key = "/cxm/applications";

/**
 *
 * Application list
 */
export const getCxmApplicationsId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ApplicationVO[]>> => {
  return Http.getRequest(
    template(getCxmApplicationsId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmApplicationsId.key = "/cxm/applications/{id}";

/**
 *
 * Get active Authorities (Non-consumable by pox-front)
 */
export const getCxmAuthoritiesActive = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthorityInfoDTO[]>> => {
  return Http.getRequest(
    getCxmAuthoritiesActive.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmAuthoritiesActive.key = "/cxm/authorities/active";

/**
 *
 * Get Authority by id
 */
export const getCxmAuthoritiesAuthority_id = (
  authority_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SaveAuthorityInfoDTO>> => {
  return Http.getRequest(
    template(getCxmAuthoritiesAuthority_id.key, { authority_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmAuthoritiesAuthority_id.key = "/cxm/authorities/{authority_id}";

/**
 *
 * Get active Authorities by Function id
 */
export const getCxmAuthoritiesFunctionFunction_id = (
  function_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthorityInfoDTO[]>> => {
  return Http.getRequest(
    template(getCxmAuthoritiesFunctionFunction_id.key, { function_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmAuthoritiesFunctionFunction_id.key =
  "/cxm/authorities/function/{function_id}";

/**
 *
 * Customers page list
 */
export const getCxmCustomers = (
  queryParams?: GetCxmCustomersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SyncUserVo>> => {
  return Http.getRequest(
    getCxmCustomers.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmCustomers.key = "/cxm/customers";

/**
 *
 * Get customer legal detail by okta id
 */
export const getCxmCustomersConsentsOkta_id = (
  okta_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CustomerConsentVo[]>> => {
  return Http.getRequest(
    template(getCxmCustomersConsentsOkta_id.key, { okta_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmCustomersConsentsOkta_id.key = "/cxm/customers/consents/{okta_id}";

/**
 *
 * Customer detail
 */
export const getCxmCustomersOkta_id = (
  okta_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OktaUser>> => {
  return Http.getRequest(
    template(getCxmCustomersOkta_id.key, { okta_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmCustomersOkta_id.key = "/cxm/customers/{okta_id}";

/**
 *
 * Get deals page
 */
export const getCxmDeals = (
  queryParams: GetCxmDealsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealPageResponse>> => {
  return Http.getRequest(
    getCxmDeals.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmDeals.key = "/cxm/deals";

/**
 *
 * Get deal detail by code
 */
export const getCxmDealsCodeDeal_code = (
  deal_code: string,
  queryParams?: GetCxmDealsCodeDeal_codeQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealDetailVo>> => {
  return Http.getRequest(
    template(getCxmDealsCodeDeal_code.key, { deal_code }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmDealsCodeDeal_code.key = "/cxm/deals/code/{deal_code}";

/**
 *
 * Get deal detail by id
 */
export const getCxmDealsDeal_id = (
  deal_id: string,
  queryParams?: GetCxmDealsDeal_idQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealDetailVo>> => {
  return Http.getRequest(
    template(getCxmDealsDeal_id.key, { deal_id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmDealsDeal_id.key = "/cxm/deals/{deal_id}";

/**
 *
 * Download a CSV file of all coupons from a bulk associated to a deal
 */
export const getCxmDealsDeal_idBulksBulk_idDownload = (
  deal_id: string,
  bulk_id: string,
  queryParams: GetCxmDealsDeal_idBulksBulk_idDownloadQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string>> => {
  return Http.getRequest(
    template(getCxmDealsDeal_idBulksBulk_idDownload.key, { deal_id, bulk_id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmDealsDeal_idBulksBulk_idDownload.key =
  "/cxm/deals/{deal_id}/bulks/{bulk_id}/download";

/**
 *
 * Get coupons page by deal id
 */
export const getCxmDealsDeal_idCoupons = (
  deal_id: string,
  queryParams: GetCxmDealsDeal_idCouponsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealCouponsPage>> => {
  return Http.getRequest(
    template(getCxmDealsDeal_idCoupons.key, { deal_id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmDealsDeal_idCoupons.key = "/cxm/deals/{deal_id}/coupons";

/**
 *
 * Get partner codes page by deal id
 */
export const getCxmDealsDeal_idPartnerCodes = (
  deal_id: string,
  queryParams: GetCxmDealsDeal_idPartnerCodesQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealPartnerCodesPage>> => {
  return Http.getRequest(
    template(getCxmDealsDeal_idPartnerCodes.key, { deal_id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmDealsDeal_idPartnerCodes.key = "/cxm/deals/{deal_id}/partner-codes";

/**
 *
 * Get B2C events by PetOwner ID
 */
export const getCxmEventsB2cPet_owner_id = (
  pet_owner_id: string,
  queryParams: GetCxmEventsB2cPet_owner_idQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EventPageResponse>> => {
  return Http.getRequest(
    template(getCxmEventsB2cPet_owner_id.key, { pet_owner_id }),
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmEventsB2cPet_owner_id.key = "/cxm/events/b2c/{pet_owner_id}";

/**
 *
 * Get active Functions (Non-consumable by pox-front)
 */
export const getCxmFunctionsActive = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FunctionInfoDTO[]>> => {
  return Http.getRequest(
    getCxmFunctionsActive.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmFunctionsActive.key = "/cxm/functions/active";

/**
 *
 * Get Function by id
 */
export const getCxmFunctionsFunction_id = (
  function_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SaveFunctionInfoDTO>> => {
  return Http.getRequest(
    template(getCxmFunctionsFunction_id.key, { function_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmFunctionsFunction_id.key = "/cxm/functions/{function_id}";

/**
 *
 * Get active Functions by Menu id
 */
export const getCxmFunctionsMenuMenu_id = (
  menu_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FunctionInfoDTO[]>> => {
  return Http.getRequest(
    template(getCxmFunctionsMenuMenu_id.key, { menu_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmFunctionsMenuMenu_id.key = "/cxm/functions/menu/{menu_id}";

/**
 *
 * Get import list by country
 */
export const getCxmImports = (
  queryParams: GetCxmImportsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ImportPageResponse>> => {
  return Http.getRequest(
    getCxmImports.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmImports.key = "/cxm/imports";

/**
 *
 * Get My import list
 */
export const getCxmImportsMine = (
  queryParams: GetCxmImportsMineQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ImportPageResponse>> => {
  return Http.getRequest(
    getCxmImportsMine.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmImportsMine.key = "/cxm/imports/mine";

/**
 *
 * B2B Points page list
 */
export const getCxmLoyaltyB2bPoints = (
  queryParams: GetCxmLoyaltyB2bPointsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyB2bPointsResponse>> => {
  return Http.getRequest(
    getCxmLoyaltyB2bPoints.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmLoyaltyB2bPoints.key = "/cxm/loyalty/b2b/points";

/**
 *
 * B2B Cumulative points by Company id
 */
export const getCxmLoyaltyB2bPointsCumulativePointsCompany_id = (
  company_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyB2bCumulativePointsVo>> => {
  return Http.getRequest(
    template(getCxmLoyaltyB2bPointsCumulativePointsCompany_id.key, {
      company_id,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmLoyaltyB2bPointsCumulativePointsCompany_id.key =
  "/cxm/loyalty/b2b/points/cumulative-points/{company_id}";

/**
 *
 * B2C Points page list
 */
export const getCxmLoyaltyPoints = (
  queryParams: GetCxmLoyaltyPointsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyPointsResponse>> => {
  return Http.getRequest(
    getCxmLoyaltyPoints.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmLoyaltyPoints.key = "/cxm/loyalty/points";

/**
 *
 * B2C Cumulative points by email or oktaId
 */
export const getCxmLoyaltyPointsCumulativePointsParameter = (
  parameter: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyCumulativePointsVo>> => {
  return Http.getRequest(
    template(getCxmLoyaltyPointsCumulativePointsParameter.key, { parameter }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmLoyaltyPointsCumulativePointsParameter.key =
  "/cxm/loyalty/points/cumulative-points/{parameter}";

/**
 *
 * Program page list
 */
export const getCxmLoyaltyPrograms = (
  queryParams?: GetCxmLoyaltyProgramsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramsResponse>> => {
  return Http.getRequest(
    getCxmLoyaltyPrograms.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmLoyaltyPrograms.key = "/cxm/loyalty/programs";

/**
 *
 * Get program
 */
export const getCxmLoyaltyProgramsId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramVo>> => {
  return Http.getRequest(
    template(getCxmLoyaltyProgramsId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmLoyaltyProgramsId.key = "/cxm/loyalty/programs/{id}";

/**
 *
 * Get active root Menus
 */
export const getCxmMenus = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuInfoDTO[]>> => {
  return Http.getRequest(
    getCxmMenus.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmMenus.key = "/cxm/menus";

/**
 *
 * Get all active Menus (Non-consumable by pox-front)
 */
export const getCxmMenusActive = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuInfoDTO[]>> => {
  return Http.getRequest(
    getCxmMenusActive.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmMenusActive.key = "/cxm/menus/active";

/**
 *
 * Get Menu by id
 */
export const getCxmMenusMenu_id = (
  menu_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SaveMenuInfoDTO>> => {
  return Http.getRequest(
    template(getCxmMenusMenu_id.key, { menu_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmMenusMenu_id.key = "/cxm/menus/{menu_id}";

/**
 *
 * Get active Menus by Parent id
 */
export const getCxmMenusParentParent_menu_id = (
  parent_menu_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MenuInfoDTO[]>> => {
  return Http.getRequest(
    template(getCxmMenusParentParent_menu_id.key, { parent_menu_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmMenusParentParent_menu_id.key = "/cxm/menus/parent/{parent_menu_id}";

/**
 *
 * Get Sidebar info
 */
export const getCxmMenusSidebarInfo = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SidebarInfoVo>> => {
  return Http.getRequest(
    getCxmMenusSidebarInfo.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmMenusSidebarInfo.key = "/cxm/menus/sidebar-info";

/**
 *
 * Partner b2b relationships page list
 */
export const getCxmPartnerRelationsB2b = (
  queryParams: GetCxmPartnerRelationsB2bQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RelationshipPageResponse>> => {
  return Http.getRequest(
    getCxmPartnerRelationsB2b.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPartnerRelationsB2b.key = "/cxm/partner-relations/b2b";

/**
 *
 * Partner b2b relationships roles
 */
export const getCxmPartnerRelationsB2bRoles = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPartnerRelationsB2bRoles.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPartnerRelationsB2bRoles.key = "/cxm/partner-relations/b2b/roles";

/**
 *
 * Partner b2c relationships page list
 */
export const getCxmPartnerRelationsB2c = (
  queryParams: GetCxmPartnerRelationsB2cQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RelationshipPageResponse>> => {
  return Http.getRequest(
    getCxmPartnerRelationsB2c.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPartnerRelationsB2c.key = "/cxm/partner-relations/b2c";

/**
 *
 * Partner page list
 */
export const getCxmPartners = (
  queryParams: GetCxmPartnersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PartnerPageResponse>> => {
  return Http.getRequest(
    getCxmPartners.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPartners.key = "/cxm/partners";

/**
 *
 * Partner detail
 */
export const getCxmPartnersId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PartnerRcSyncInformationQueryResponse>> => {
  return Http.getRequest(
    template(getCxmPartnersId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPartnersId.key = "/cxm/partners/{id}";

/**
 *
 * Partner detail roles
 */
export const getCxmPartnerRoles = (
  queryParams: GetCxmPartnerRoles,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PartnerRoles>> => {
  return Http.getRequest(
    getCxmPartnerRoles.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPartnerRoles.key = "/cxm/partners/roles";

/**
 *
 * Partner detail roles edit
 */
export const patchCxmPartnerRoles = (
  id: string,
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SyncUserVo>> => {
  return Http.patchRequest(
    template(patchCxmPartnerRoles.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmPartnerRoles.key = "/cxm/partners/{id}/roles";

/**
 *
 * Get personal configuration
 */
export const getCxmPersonalConfiguration = (
  queryParams: GetCxmPersonalConfigurationQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PersonalConfigurationDTO>> => {
  return Http.getRequest(
    getCxmPersonalConfiguration.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPersonalConfiguration.key = "/cxm/personal-configuration";

/**
 *
 * Get pet detail by id
 */
export const getCxmPetsDetailId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetDetailVo>> => {
  return Http.getRequest(
    template(getCxmPetsDetailId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsDetailId.key = "/cxm/pets/detail/{id}";

/**
 *
 * Get pet detail by id
 */
export const getCxmPetsDetailIdWeights = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetWeightVo[]>> => {
  return Http.getRequest(
    template(getCxmPetsDetailIdWeights.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsDetailIdWeights.key = "/cxm/pets/detail/{id}/weights";

/**
 *
 * Get Activities codes list
 */
export const getCxmPetsMetadataActivityCodes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataActivityCodes.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataActivityCodes.key = "/cxm/pets/metadata/activity-codes";

/**
 *
 * Get Pet metadata list
 */
export const getCxmPetsMetadataBreeds = (
  queryParams?: GetCxmPetsMetadataBreedsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetMetadataVo[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataBreeds.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataBreeds.key = "/cxm/pets/metadata/breeds";

/**
 *
 * Get hair list
 */
export const getCxmPetsMetadataHair = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataHair.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataHair.key = "/cxm/pets/metadata/hair";

/**
 *
 * Get Lifestyles list
 */
export const getCxmPetsMetadataLifestyles = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataLifestyles.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataLifestyles.key = "/cxm/pets/metadata/lifestyles";

/**
 *
 * Get Purchase Places Preference list
 */
export const getCxmPetsMetadataPurchasePlacesPreference = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataPurchasePlacesPreference.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataPurchasePlacesPreference.key =
  "/cxm/pets/metadata/purchase-places-preference";

/**
 *
 * Get Sensitivities list
 */
export const getCxmPetsMetadataSensitivities = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataSensitivities.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataSensitivities.key = "/cxm/pets/metadata/sensitivities";

/**
 *
 * Get Species codes list
 */
export const getCxmPetsMetadataSpeciesCodes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmPetsMetadataSpeciesCodes.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsMetadataSpeciesCodes.key = "/cxm/pets/metadata/species-codes";

/**
 *
 * Get All pets by customer id
 */
export const getCxmPetsOkta_id = (
  okta_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetDetailVo[]>> => {
  return Http.getRequest(
    template(getCxmPetsOkta_id.key, { okta_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmPetsOkta_id.key = "/cxm/pets/{okta_id}";

/**
 *
 * Get active scopes
 */
export const getCxmScopes = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ScopeDTO[]>> => {
  return Http.getRequest(
    getCxmScopes.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmScopes.key = "/cxm/scopes";

/**
 *
 * Get templates
 */
export const getCxmTemplates = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TemplatesResponse>> => {
  return Http.getRequest(
    getCxmTemplates.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmTemplates.key = "/cxm/templates";

/**
 *
 * Get time zones
 */
export const getCxmTimeZones = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getCxmTimeZones.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCxmTimeZones.key = "/cxm/time-zones";

/**
 *
 * Update a deal
 */
export const patchCxmDealsDeal_id = (
  deal_id: string,
  requestBody: {
    code?: string;
    description?: string;
    details?: string;
    endDate?: string;
    giftName?: string;
    giftUrl?: string;
    /**
     *
     * Supports only PNG/JPEG files
     * - Format: binary
     */
    image?: string;
    name?: string;
    offerDetails?: string;
    paused?: string;
    petNeeded?: string;
    redeemInfo?: string;
    startDate?: string;
    termsAndConditions?: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealResponse>> => {
  return Http.patchRequest(
    template(patchCxmDealsDeal_id.key, { deal_id }),
    undefined,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmDealsDeal_id.key = "/cxm/deals/{deal_id}";

/**
 *
 * Update a deal coupon
 */
export const patchCxmDealsDeal_idCouponsCoupon_code = (
  deal_id: string,
  coupon_code: string,
  requestBody: DealCouponDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealCouponResponse>> => {
  return Http.patchRequest(
    template(patchCxmDealsDeal_idCouponsCoupon_code.key, {
      deal_id,
      coupon_code,
    }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmDealsDeal_idCouponsCoupon_code.key =
  "/cxm/deals/{deal_id}/coupons/{coupon_code}";

/**
 *
 * Add events to a program
 */
export const patchCxmLoyaltyProgramsIdEventsName = (
  id: string,
  name: string,
  requestBody: EditEventDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramVo>> => {
  return Http.patchRequest(
    template(patchCxmLoyaltyProgramsIdEventsName.key, { id, name }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmLoyaltyProgramsIdEventsName.key =
  "/cxm/loyalty/programs/{id}/events/{name}";

/**
 *
 * Patch pet by id
 */
export const patchCxmPetsId = (
  id: string,
  requestBody: PatchPetDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetDetailVo>> => {
  return Http.patchRequest(
    template(patchCxmPetsId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmPetsId.key = "/cxm/pets/{id}";

/**
 *
 * Patch pet photo
 */
export const patchCxmPetsIdPhoto = (
  id: string,
  requestBody: {
    /**
     *
     * Supports only PNG/JPEG files
     * - Format: binary
     */
    photo: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetDetailVo>> => {
  return Http.patchRequest(
    template(patchCxmPetsIdPhoto.key, { id }),
    undefined,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmPetsIdPhoto.key = "/cxm/pets/{id}/photo";

/**
 *
 * Patch pet weight by id
 */
export const patchCxmPetsPet_idWeightsWeight_id = (
  pet_id: string,
  weight_id: string,
  requestBody: PetWeightDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetWeightVo>> => {
  return Http.patchRequest(
    template(patchCxmPetsPet_idWeightsWeight_id.key, { pet_id, weight_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
patchCxmPetsPet_idWeightsWeight_id.key =
  "/cxm/pets/{pet_id}/weights/{weight_id}";

/**
 *
 * Add authority
 */
export const postCxmAuthorities = (
  requestBody: SaveAuthorityInfoDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CreationResponseDTO>> => {
  return Http.postRequest(
    postCxmAuthorities.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmAuthorities.key = "/cxm/authorities";

/**
 *
 * Get service current time
 */
export const postCxmCurrentTime = (
  requestBody: TimeZoneDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CurrentTimeDTO>> => {
  return Http.postRequest(
    postCxmCurrentTime.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmCurrentTime.key = "/cxm/current-time";

/**
 *
 * Create a deal
 */
export const postCxmDeals = (
  requestBody: {
    code: string;
    description: string;
    details: string;
    endDate: string;
    giftName: string;
    giftUrl: string;
    /**
     *
     * Supports only PNG/JPEG files
     * - Format: binary
     */
    image: string;
    name: string;
    offerDetails: string;
    petNeeded: string;
    redeemInfo: string;
    startDate: string;
    termsAndConditions: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DealVo>> => {
  return Http.postRequest(
    postCxmDeals.key,
    undefined,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postCxmDeals.key = "/cxm/deals";

/**
 *
 * Create a bulk and a list of linked coupons
 */
export const postCxmDealsDeal_idBulks = (
  deal_id: string,
  requestBody: BulkDealCouponDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postCxmDealsDeal_idBulks.key, { deal_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmDealsDeal_idBulks.key = "/cxm/deals/{deal_id}/bulks";

/**
 *
 * Send an email gift for a partner
 */
export const postCxmDealsDeal_idCouponsCoupon_codeEmail = (
  deal_id: string,
  coupon_code: string,
  requestBody: EmailGiftDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postCxmDealsDeal_idCouponsCoupon_codeEmail.key, {
      deal_id,
      coupon_code,
    }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmDealsDeal_idCouponsCoupon_codeEmail.key =
  "/cxm/deals/{deal_id}/coupons/{coupon_code}/email";

/**
 *
 * Import partner codes of a deal
 */
export const postCxmDealsDeal_idPartnerCodesImport = (
  deal_id: string,
  requestBody: {
    /**
     *
     * Supports txt/csv files
     * - Format: binary
     */
    file: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postCxmDealsDeal_idPartnerCodesImport.key, { deal_id }),
    undefined,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postCxmDealsDeal_idPartnerCodesImport.key =
  "/cxm/deals/{deal_id}/partner-codes/import";

/**
 *
 * Add function
 */
export const postCxmFunctions = (
  requestBody: SaveFunctionInfoDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CreationResponseDTO>> => {
  return Http.postRequest(
    postCxmFunctions.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmFunctions.key = "/cxm/functions";

/**
 *
 * Import Pets & PetOwners
 */
export const postCxmImports = (
  requestBody: {
    /**
     *
     * Supports csv files
     * - Format: binary
     */
    file: string;
    source: string;
  },
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postCxmImports.key,
    undefined,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(_CONSTANT2, configOverride)
  );
};

/** Key is end point string without base url */
postCxmImports.key = "/cxm/imports";

/**
 *
 * Manual action B2B points
 */
export const postCxmLoyaltyB2bPointsProgram_id = (
  program_id: string,
  requestBody: LoyaltyB2bPointsAddRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyB2bPointsAddVo>> => {
  return Http.postRequest(
    template(postCxmLoyaltyB2bPointsProgram_id.key, { program_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmLoyaltyB2bPointsProgram_id.key = "/cxm/loyalty/b2b/points/{program_id}";

/**
 *
 * Manual action B2C points
 */
export const postCxmLoyaltyPoints = (
  requestBody: LoyaltyPointsAddRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyPointsAddVo>> => {
  return Http.postRequest(
    postCxmLoyaltyPoints.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmLoyaltyPoints.key = "/cxm/loyalty/points";

/**
 *
 * Add program
 */
export const postCxmLoyaltyPrograms = (
  requestBody: LoyaltyProgramAddRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramVo>> => {
  return Http.postRequest(
    postCxmLoyaltyPrograms.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmLoyaltyPrograms.key = "/cxm/loyalty/programs";

/**
 *
 * Add events to a program
 */
export const postCxmLoyaltyProgramsIdEvents = (
  id: string,
  requestBody: SaveEventDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramVo>> => {
  return Http.postRequest(
    template(postCxmLoyaltyProgramsIdEvents.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmLoyaltyProgramsIdEvents.key = "/cxm/loyalty/programs/{id}/events";

/**
 *
 * Add menu
 */
export const postCxmMenus = (
  requestBody: SaveMenuInfoDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CreationResponseDTO>> => {
  return Http.postRequest(
    postCxmMenus.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmMenus.key = "/cxm/menus";

/**
 *
 * Get Countries by okta token
 */
export const postCxmOktaAuthCountries = (
  requestBody: JwtTokenRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CountryResponse>> => {
  return Http.postRequest(
    postCxmOktaAuthCountries.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmOktaAuthCountries.key = "/cxm/okta/auth/countries";

/**
 *
 * Generate jwt token by okta token
 */
export const postCxmOktaAuthLoginOut = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  return Http.postRequest(
    postCxmOktaAuthLoginOut.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmOktaAuthLoginOut.key = "/cxm/okta/auth/login-out";

/**
 *
 * Po okta call back
 */
export const postCxmOktaAuthPoCallback = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  return Http.postRequest(
    postCxmOktaAuthPoCallback.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmOktaAuthPoCallback.key = "/cxm/okta/auth/po/callback";

/**
 *
 * Generate jwt token by okta token
 */
export const postCxmOktaAuthToken = (
  requestBody: JwtTokenRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<JwtTokenResponse>> => {
  return Http.postRequest(
    postCxmOktaAuthToken.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmOktaAuthToken.key = "/cxm/okta/auth/token";

/**
 *
 * Create B2B Relation
 */
export const postCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_id =
  (
    partner_id: string,
    okta_id: string,
    client_id: string,
    requestBody: SaveB2bRelationDTO,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<Relationship>> => {
    return Http.postRequest(
      template(
        postCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_id.key,
        { partner_id, okta_id, client_id }
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
postCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_id.key =
  "/cxm/partner-relations/b2b/partners/{partner_id}/users/{okta_id}/applications/{client_id}";

/**
 *
 * Create B2C Relation
 */
export const postCxmPartnerRelationsB2cPartnersPartner_idUsersOkta_id = (
  partner_id: string,
  okta_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<B2cRelationCreationResponse>> => {
  return Http.postRequest(
    template(postCxmPartnerRelationsB2cPartnersPartner_idUsersOkta_id.key, {
      partner_id,
      okta_id,
    }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmPartnerRelationsB2cPartnersPartner_idUsersOkta_id.key =
  "/cxm/partner-relations/b2c/partners/{partner_id}/users/{okta_id}";

/**
 *
 * Add personal configuration
 */
export const postCxmPersonalConfiguration = (
  requestBody: SavePersonalConfigDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CreationResponseDTO>> => {
  return Http.postRequest(
    postCxmPersonalConfiguration.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmPersonalConfiguration.key = "/cxm/personal-configuration";

/**
 *
 * Attach weight to pet by id
 */
export const postCxmPetsIdWeights = (
  id: string,
  requestBody: PetWeightDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PetWeightVo>> => {
  return Http.postRequest(
    template(postCxmPetsIdWeights.key, { id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmPetsIdWeights.key = "/cxm/pets/{id}/weights";

/**
 *
 * Add scope (Non-consumable by pox-front)
 */
export const postCxmScopes = (
  requestBody: SaveScopeDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CreationResponseDTO>> => {
  return Http.postRequest(
    postCxmScopes.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmScopes.key = "/cxm/scopes";

/**
 *
 * Get authorized resources by scope names (Temporary; will be deleted, Non-consumable by pox-front)
 */
export const postCxmScopesAuthorizedResources = (
  requestBody: string[],
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AuthorityShot[]>> => {
  return Http.postRequest(
    postCxmScopesAuthorizedResources.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCxmScopesAuthorizedResources.key = "/cxm/scopes/authorized-resources";

/**
 *
 * Update authority
 */
export const putCxmAuthoritiesAuthority_id = (
  authority_id: string,
  requestBody: SaveAuthorityInfoDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putCxmAuthoritiesAuthority_id.key, { authority_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putCxmAuthoritiesAuthority_id.key = "/cxm/authorities/{authority_id}";

/**
 *
 * Update function
 */
export const putCxmFunctionsFunction_id = (
  function_id: string,
  requestBody: SaveFunctionInfoDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putCxmFunctionsFunction_id.key, { function_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putCxmFunctionsFunction_id.key = "/cxm/functions/{function_id}";

/**
 *
 * Edit program
 */
export const putCxmLoyaltyPrograms = (
  requestBody: LoyaltyProgramAddRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramVo>> => {
  return Http.putRequest(
    putCxmLoyaltyPrograms.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putCxmLoyaltyPrograms.key = "/cxm/loyalty/programs";

/**
 *
 * Edit program end date
 */
export const putCxmLoyaltyProgramsEndDate = (
  requestBody: LoyaltyProgramEditEndDateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoyaltyProgramVo>> => {
  return Http.putRequest(
    putCxmLoyaltyProgramsEndDate.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putCxmLoyaltyProgramsEndDate.key = "/cxm/loyalty/programs/end-date";

/**
 *
 * Update menu
 */
export const putCxmMenusMenu_id = (
  menu_id: string,
  requestBody: SaveMenuInfoDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putCxmMenusMenu_id.key, { menu_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putCxmMenusMenu_id.key = "/cxm/menus/{menu_id}";

/**
 *
 * Remove B2B-Relation's AppRole
 */
export const putCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_idAppRolesRole_name =
  (
    partner_id: string,
    okta_id: string,
    client_id: string,
    role_name: string,
    configOverride?: AxiosRequestConfig
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_idAppRolesRole_name.key,
        { partner_id, okta_id, client_id, role_name }
      ),
      undefined,
      undefined,
      _CONSTANT1,
      overrideConfig(_CONSTANT0, configOverride)
    );
  };

/** Key is end point string without base url */
putCxmPartnerRelationsB2bPartnersPartner_idUsersOkta_idApplicationsClient_idAppRolesRole_name.key =
  "/cxm/partner-relations/b2b/partners/{partner_id}/users/{okta_id}/applications/{client_id}/appRoles/{role_name}";

/**
 *
 * Update scope (Non-consumable by pox-front)
 */
export const putCxmScopesScope_id = (
  scope_id: string,
  requestBody: SaveScopeDTO,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putCxmScopesScope_id.key, { scope_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putCxmScopesScope_id.key = "/cxm/scopes/{scope_id}";
export const _CONSTANT0 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
export const _CONSTANT1 = [{ "Bearer Authentication": [] }];
export const _CONSTANT2 = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
};
