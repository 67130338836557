import Edit from "pages/customer/detail/edit";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
const CustomerList = lazy(() => import("../pages/customer/list"));
const CustomerDetail = lazy(() => import("../pages/customer/detail"));

function CustomerRoutes() {
  return (
    <Switch>
      <Route path="/app/customer/list" component={CustomerList} exact />
      <Route
        path="/app/customer/list/detail/:id"
        component={CustomerDetail}
        exact
      />
      <Route
        path="/app/customer/list/detail/:id/edit/:petId"
        component={Edit}
        exact
      />
    </Switch>
  );
}

export default CustomerRoutes;
