import { DetailedHTMLProps, HTMLAttributes } from "react";
import * as Icons from "./icons";
import * as Flags from "./flags";
import classNames from "classnames";

function pascalCase(str: string) {
  return str
    .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    })
    .replace(" ", "");
}

function cleanType(type: string) {
  if (Icons.IconsList.find((t) => t === type)) {
    return type;
  }
  if (Flags.FlagsList.find((t) => t === type)) {
    return type;
  }
  return pascalCase(
    type.replace("icon-", "").replace("-", " ").replace("_", " ")
  );
}

function VIcon({
  type,
  ...props
}: { type: string } & DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) {
  if (!type) {
    return null;
  }
  const Icon =
    (Icons as any)[cleanType(type)] || (Flags as any)[cleanType(type)];
  if (!Icon) {
    console.warn(`Icon ${type} was not found!`);
    return null;
  }
  return (
    <span
      role="img"
      {...props}
      className={classNames("anticon", props.className)}
    >
      <svg
        style={{
          height: "1em",
          width: "1em",
          display: "inline-block",
        }}
      >
        <Icon />
      </svg>
    </span>
  );
}
export const VColorIconColor = VIcon;

export const LocalIcon = VIcon;

export default VIcon;
