import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

//Loyalty index
const CustomerPointManagement = lazy(
  () => import("../pages/loyalty/customer-point-management/index")
);
const ProgramListing = lazy(
  () => import("../pages/loyalty/program-listing/index")
);

const ProgramDetails = lazy(
  () => import("../pages/loyalty/program-listing/components/programShowDetails")
);

const ProgramShowDetails = lazy(
  () => import("../pages/loyalty/program-listing/components/ProgramDetails")
);

function LoyaltyRoutes() {
  return (
    <Switch>
      <Redirect path="/app/loyalty" to="/app/loyalty/point-management" exact />
      <Route
        path="/app/loyalty/point-management"
        component={CustomerPointManagement}
        exact
      />
      <Route
        path="/app/loyalty/program-listing"
        component={ProgramListing}
        exact
      />
      <Route
        path="/app/loyalty/program-listing/program-details/:id?/:show?"
        component={ProgramDetails}
        exact
      />
      <Route
        path="/app/loyalty/program-listing/program-details-show/:id"
        component={ProgramShowDetails}
        exact
      />
    </Switch>
  );
}

export default LoyaltyRoutes;
