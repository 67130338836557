import { Tokens } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { message } from "antd";
import {
  getCxmMenusSidebarInfo,
  postCxmOktaAuthCountries,
  postCxmOktaAuthToken,
} from "api/services";
import { ReadOnlyMenuInfo } from "api/types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Enum } from "utils/enums";
import { OpenMessage, logOut } from "utils/tools";

const { countryCode } = Enum;

interface Route {
  breadcrumbName: string;
  icon: string;
  name: string;
  path: string;
  routes: Route[];
}

interface MenuInfoWithRoutes extends ReadOnlyMenuInfo {
  routes: Route[];
}

function useInit() {
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState<MenuInfoWithRoutes[]>([]);
  const [accessFunctions, setAccessFunctions] = useState<Map<string, boolean>>(
    new Map()
  );
  const firstPage = useRef<string>();
  const history = useHistory();
  const { t } = useTranslation("common");

  const basename = sessionStorage.getItem(
    "basename"
  ) as keyof typeof countryCode;
  const selectedCountry = countryCode[basename]?.value;

  const handleUserStorage = async (token: string) => {
    try {
      const data = await postCxmOktaAuthToken({
        oktaToken: token,
        country: selectedCountry,
        idToken: localStorage.getItem("idToken")!,
      });
      localStorage.setItem("token", data?.token!);
      sessionStorage.setItem("employeeInfo", JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryList = async (token: string) => {
    try {
      const res = await postCxmOktaAuthCountries({
        oktaToken: token,
      });
      const countries = res.countries;
      if (countries && countries.length) {
        sessionStorage.setItem("user-country-list", JSON.stringify(countries));
        const isIncludeSelectCountry = countries.some((countrie) => {
          return countrie.code === selectedCountry;
        });
        if (!isIncludeSelectCountry) {
          message.error("This account does not have permission!");
          setTimeout(() => {
            history.push("/logout");
          }, 2000);
          return;
        }
      }
    } catch (error) {
      OpenMessage({
        success: false,
        message: t("token_expired"),
        handleAfterClose: () => {
          logOut();
        },
        isOktaError: true,
      });
    }
  };

  const getMenu = async () => {
    try {
      const { menuList, funcList } = await getCxmMenusSidebarInfo();
      if (!menuList?.length) {
        logOut();
      }
      firstPage.current = menuList?.[0]?.path;
      if (["/app/", "/app"].includes(history.location.pathname)) {
        menuList?.[0]?.path && history.replace(menuList[0].path);
      }
      setMenu((menuList as MenuInfoWithRoutes[]) || []);
      setAccessFunctions(new Map(funcList?.map((item) => [item, true])));
    } catch (error) {
      logOut();
    }
  };

  const init = async () => {
    let newAccessToken: string = authState?.accessToken?.accessToken!;
    if (
      authState?.accessToken?.scopes.includes("openid") &&
      authState.isAuthenticated
    ) {
      localStorage.setItem("idToken", authState.idToken?.idToken!);
      // In order to get CXM scopes, we have to renew token with empty scopes lists
      // Call renewToken, if it fails fallback with signInWithRedirect
      try {
        const res = await oktaAuth.token.renewTokens({
          scopes: [],
        });
        newAccessToken = res.accessToken?.accessToken!;
      } catch (error) {
        return await oktaAuth.signInWithRedirect({
          scopes: [],
        });
      }
    }
    await handleUserStorage(newAccessToken);
    await getCountryList(newAccessToken);
    await getMenu();
    if (!localStorage.getItem("token")) {
      return logOut();
    }
    setLoading(false);
  };
  useEffect(() => {
    if (authState && !authState.accessToken?.accessToken) {
      return logOut();
    }
    init();
  }, [authState]);

  return {
    loading,
    menu,
    accessFunctions,
  };
}

export default useInit;
