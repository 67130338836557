import { OktaAuth } from "@okta/okta-auth-js";
import { getBasename } from "./utils/tools";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const basename = getBasename();

const baseConfig = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: window.location.origin + `${basename}/implicit/callback`,
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
};

export const oktaScopesConfig = new OktaAuth({
  ...baseConfig,
  scopes: ["openid", "profile", "email"],
});

export const oktaConfig = new OktaAuth({
  ...baseConfig,
  scopes: [],
});
