export { ReactComponent as ADog1 } from "./a-Dog1.svg";
export { ReactComponent as AUseradd } from "./a-UserAdd.svg";
export { ReactComponent as Add } from "./Add.svg";
export { ReactComponent as ArrowLeft } from "./arrow-left.svg";
export { ReactComponent as Au } from "./AU.svg";
export { ReactComponent as Cat1 } from "./Cat-1.svg";
export { ReactComponent as Cat } from "./cat.svg";
export { ReactComponent as Category1 } from "./Category-1.svg";
export { ReactComponent as Category } from "./category.svg";
export { ReactComponent as Close } from "./close.svg";
export { ReactComponent as Cn } from "./CN.svg";
export { ReactComponent as Computer } from "./computer.svg";
export { ReactComponent as Daimakuai } from "./daimakuai.svg";
export { ReactComponent as Database } from "./database.svg";
export { ReactComponent as Diqiu } from "./diqiu.svg";
export { ReactComponent as Dog } from "./dog.svg";
export { ReactComponent as Edit } from "./edit.svg";
export { ReactComponent as Experience } from "./Experience.svg";
export { ReactComponent as Filter } from "./filter.svg";
export { ReactComponent as Fr } from "./FR.svg";
export { ReactComponent as Friendly } from "./Friendly.svg";
export { ReactComponent as Gb } from "./gb.svg";
export { ReactComponent as IconNonuowei } from "./icon_NOnuowei.svg";
export { ReactComponent as It } from "./IT.svg";
export { ReactComponent as Jp } from "./JP.svg";
export { ReactComponent as LeftIcon } from "./Left-Icon.svg";
export { ReactComponent as Lianjie } from "./lianjie.svg";
export { ReactComponent as ListView } from "./List-view.svg";
export { ReactComponent as ListView1 } from "./List-view1.svg";
export { ReactComponent as Look } from "./look.svg";
export { ReactComponent as Mokuai } from "./mokuai.svg";
export { ReactComponent as NewTab } from "./NewTab.svg";
export { ReactComponent as Nl } from "./NL.svg";
export { ReactComponent as Nz } from "./NZ.svg";
export { ReactComponent as Product } from "./product.svg";
export { ReactComponent as RcAdd } from "./rc-add.svg";
export { ReactComponent as RcCampaign } from "./rc-campaign.svg";
export { ReactComponent as RcCheck } from "./rc-check.svg";
export { ReactComponent as RcCloseCircle } from "./rc-close-circle.svg";
export { ReactComponent as RcClose } from "./rc-close.svg";
export { ReactComponent as RcCustomer } from "./rc-customer.svg";
export { ReactComponent as RcDate } from "./rc-date.svg";
export { ReactComponent as RcDelete } from "./rc-delete.svg";
export { ReactComponent as RcDuplicate } from "./rc-duplicate.svg";
export { ReactComponent as RcEdit } from "./rc-edit.svg";
export { ReactComponent as RcEmail } from "./rc-email.svg";
export { ReactComponent as RcExport } from "./rc-export.svg";
export { ReactComponent as RcEye } from "./rc-eye.svg";
export { ReactComponent as RcFilter } from "./rc-filter.svg";
export { ReactComponent as RcGuanlian } from "./rc-guanlian.svg";
export { ReactComponent as RcPartner } from "./rc-partner.svg";
export { ReactComponent as RcPause } from "./rc-pause.svg";
export { ReactComponent as RcProduct } from "./rc-product.svg";
export { ReactComponent as RcResume } from "./rc-resume.svg";
export { ReactComponent as RcStop } from "./rc-stop.svg";
export { ReactComponent as RcSuccess } from "./rc-success.svg";
export { ReactComponent as RcTest } from "./rc-test.svg";
export { ReactComponent as RcWarning } from "./rc-warning.svg";
export { ReactComponent as Shape } from "./Shape.svg";
export { ReactComponent as Share } from "./Share.svg";
export { ReactComponent as Shop } from "./shop.svg";
export { ReactComponent as Similarproducts } from "./Similarproducts.svg";
export { ReactComponent as Sousuo } from "./sousuo.svg";
export { ReactComponent as Tag } from "./tag.svg";
export { ReactComponent as Team } from "./team.svg";
export { ReactComponent as User } from "./user.svg";
export { ReactComponent as Weight } from "./Weight.svg";
export { ReactComponent as Xiangqing } from "./xiangqing.svg";
export { ReactComponent as Xiangshangsanjiaoxing } from "./xiangshangsanjiaoxing.svg";
export { ReactComponent as Xiazai3 } from "./xiazai3.svg";
export { ReactComponent as YanjingXianshi } from "./yanjing_xianshi.svg";

export const IconsList = [
  "ADog1",
  "AUseradd",
  "Add",
  "ArrowLeft",
  "Au",
  "Cat1",
  "Cat",
  "Category1",
  "Category",
  "Close",
  "Cn",
  "Computer",
  "Daimakuai",
  "Database",
  "Diqiu",
  "Dog",
  "Edit",
  "Experience",
  "Filter",
  "Fr",
  "Friendly",
  "Gb",
  "IconNonuowei",
  "It",
  "Jp",
  "LeftIcon",
  "Lianjie",
  "ListView",
  "ListView1",
  "Look",
  "Mokuai",
  "NewTab",
  "Nz",
  "Nl",
  "Product",
  "RcAdd",
  "RcCampaign",
  "RcCheck",
  "RcCloseCircle",
  "RcClose",
  "RcCustomer",
  "RcDate",
  "RcDelete",
  "RcDuplicate",
  "RcEdit",
  "RcEmail",
  "RcExport",
  "RcEye",
  "RcFilter",
  "RcGuanlian",
  "RcPartner",
  "RcPause",
  "RcProduct",
  "RcResume",
  "RcStop",
  "RcSuccess",
  "RcTest",
  "RcWarning",
  "Shape",
  "Share",
  "Shop",
  "Similarproducts",
  "Sousuo",
  "Tag",
  "Team",
  "User",
  "Weight",
  "Xiangqing",
  "Xiangshangsanjiaoxing",
  "Xiazai3",
  "YanjingXianshi",
] as const;

export type TIcons = typeof IconsList[number];
