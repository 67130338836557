import { ReactNode, Suspense } from "react";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";

import { OpenMessage, getBasename, logOut } from "./utils/tools";
import enUS from "antd/lib/locale/en_US";
import { oktaConfig, oktaScopesConfig } from "./oktaConfig";
import LoadingForRC from "./components/LoadingForRC";
import ScrollToTop from "./layout/ScrollToTop";
import const_config from "./utils/const_config";
import Routes from "router";
import "./global.scss";
import { useTranslation } from "react-i18next";

Spin.setDefaultIndicator(<LoadingForRC />);

const basename = getBasename();

const OktaWrapper = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(
      toRelativeUrl(originalUri?.replace(basename, ""), window.location.origin)
    );
  };
  return (
    <Security
      oktaAuth={oktaScopesConfig}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  );
};

function App() {
  return (
    <div className="App" id="app">
      <ConfigProvider
        locale={enUS}
        getPopupContainer={const_config.POPUP_CONTAINER}
      >
        <Router basename={basename}>
          <OktaWrapper>
            <ScrollToTop>
              <Suspense fallback={<Spin size="large" />}>
                <Routes />
              </Suspense>
            </ScrollToTop>
          </OktaWrapper>
        </Router>
      </ConfigProvider>
    </div>
  );
}
export default App;
