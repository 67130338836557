import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import { Spin } from "antd";

const LoginCallback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  useEffect(() => {
    oktaAuth.storeTokensFromRedirect();
  }, [oktaAuth]);

  useEffect(() => {
    authState?.accessToken?.accessToken && history.push("/app");
  }, [authState]);

  return (
    <div className={styles["login-callback"]}>
      <Spin spinning={true} className={styles["login-callback-spin"]} />
    </div>
  );
};

export default LoginCallback;
