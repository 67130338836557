// 因iconfont cdn 无法生产 临时解决方案, 待cdn恢复 删除此代码
// iconFont的icon path

export const localSvg = {
  "icon-daimakuai": `<path d="M512 98.20689688h314.5655175C826.53241344 98.20689688 826.48275875 925.76 826.48275875 925.76L197.46758656 925.79310312C197.53379281 925.79310312 197.51724125 512 197.51724125 512l-0.04965469-212.24275875L354.75862062 98.20689688H512zM312.07172375 44.77793094l-173.79310313 223.44827625A33.10344844 33.10344844 0 0 0 131.31034437 288.55172375V925.67724125A66.24 66.24 0 0 0 197.46758656 992h629.06482688A66.22344844 66.22344844 0 0 0 892.68965563 925.76V98.24A66.14068969 66.14068969 0 0 0 826.5655175 32H338.20689687a33.10344844 33.10344844 0 0 0-26.13517312 12.77793094zM329.93103406 263.85655156C329.93103406 263.75724125 197.46758656 263.72413812 197.46758656 263.72413812v66.20689594H329.93103406c36.54620719 0 66.20689687-29.56137938 66.20689688-66.0744825V98.20689688h-66.20689688v165.64965468z m128.70620719 461.62758657a33.10344844 33.10344844 0 0 0 64.76689688 13.77103406l58.51034437-275.22206907a33.10344844 33.10344844 0 0 0-64.76689594-13.77103406l-58.51034531 275.22206906z m259.18344844-109.82068969a24.54620719 24.54620719 0 0 1 0-34.7255175l-96.82758656 96.811035a33.10344844 33.10344844 0 0 0 46.84137937 46.80827531l96.79448344-96.7944825a41.66068969 41.66068969 0 0 0 0-58.92413813l-96.811035-96.7944825a33.10344844 33.10344844 0 0 0-46.80827531 46.80827532l96.81103406 96.82758656z m-441.89793094-46.80827625a41.66068969 41.66068969 0 0 0 0 58.90758656l96.81103406 96.7944825a33.10344844 33.10344844 0 0 0 46.80827625-46.80827531l-96.811035-96.811035c9.6 9.6 9.6 25.1255175 0 34.7255175l96.82758657-96.811035a33.10344844 33.10344844 0 0 0-46.84137938-46.80827532l-96.7944825 96.81103407z" p-id="6103"></path>`,
  "icon-rc-add": `<path d="M874.666667 469.333333H554.666667V149.333333c0-23.466667-19.2-42.666667-42.666667-42.666666s-42.666667 19.2-42.666667 42.666666v320H149.333333c-23.466667 0-42.666667 19.2-42.666666 42.666667s19.2 42.666667 42.666666 42.666667h320v320c0 23.466667 19.2 42.666667 42.666667 42.666666s42.666667-19.2 42.666667-42.666666V554.666667h320c23.466667 0 42.666667-19.2 42.666666-42.666667s-19.2-42.666667-42.666666-42.666667z" p-id="2069"></path>`,
  "icon-rc-check": `<path d="M498.4125263 70.13093706c236.56564938 0 428.25397285 191.74355626 428.25397287 428.28158924 0 236.56564938-191.74355626 428.25397285-428.25397287 428.25397287C261.79164286 926.66649917 70.13093706 734.92294291 70.13093706 498.4125263 70.13093706 261.79164286 261.87449331 70.13093706 498.4125263 70.13093706z m0 55.23363206C292.36345869 125.36456912 125.36456912 292.3358423 125.36456912 498.4125263c0 206.02145123 166.97127188 373.02033951 373.04795718 373.0203395 206.02145123 0 373.02033951-166.97127188 373.0203395-373.0203395C871.4328658 292.36345869 704.4892103 125.36456912 498.4125263 125.36456912z m186.49636156 194.89187462a32.56022661 32.56022661 0 0 1 46.67241939 45.37442859l-283.07236786 291.13647913a32.56022661 32.56022661 0 0 1-45.62298122 1.02182306l-137.47651192-129.41240192a32.56022661 32.56022661 0 1 1 44.60115816-47.39045705l114.44408697 107.70558419 260.45419648-268.435456z" p-id="2233"></path>`,
  "icon-arrow-left": `<path d="M702.41610837 168.24550684l-66.61421663-66.61421516-410.40012962 410.58866043 410.36870831 410.18017898 66.64563794-66.67706069-343.72307039-343.53453959z" p-id="4093"></path>`,
};

const appendIconFontSVG = (svgs) => {
  const svgList = Object.entries(svgs).map(([name, path]) => {
    return `<symbol id="${name}" viewBox="0 0 1024 1024">${path}</symbol>`;
  });
  let stringHtml = `<svg aria-hidden="true" style="position: absolute; width: 0px; height: 0px; overflow: hidden;">${svgList.join(
    " "
  )}</svg>`;
  const box = document.createElement("div");
  box.innerHTML = stringHtml;
  stringHtml = null;
  const svgHtml = box.querySelector("svg");
  document.body.prepend(svgHtml);
};

appendIconFontSVG(localSvg);
