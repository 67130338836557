import { lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

const CustomerPointManagement = lazy(
  () => import("../pages/loyalty-b2b/customer-point-management/index")
);
const ProgramListing = lazy(
  () => import("../pages/loyalty-b2b/program-listing/index")
);
const ProgramDetails = lazy(
  () => import("../pages/loyalty-b2b/program-listing/program-details/index")
);

function LoyaltyB2BRoutes() {
  return (
    <Switch>
      <Redirect
        path="/app/loyalty-b2b"
        to="/app/loyalty-b2b/point-management"
        exact
      />
      <Route
        path="/app/loyalty-b2b/point-management"
        component={CustomerPointManagement}
        exact
      />
      <Route
        path="/app/loyalty-b2b/program-listing"
        component={ProgramListing}
        exact
      />
      <Route
        path="/app/loyalty-b2b/program-listing/:id"
        component={ProgramDetails}
        exact
      />
    </Switch>
  );
}

export default LoyaltyB2BRoutes;
