import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export default function Footer() {
  const { t } = useTranslation("common");
  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.content}>
          <span className={styles.text}>{t("copyright")}</span>
        </div>
      </div>
    </div>
  );
}
