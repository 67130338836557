import { Spin } from "antd";
import styles from "./styles.module.scss";
function Spinner() {
  return (
    <div className={styles.spinner}>
      <Spin size="large" />
    </div>
  );
}

export default Spinner;
