import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// partner
const PartnerList = lazy(() => import("../pages/partner/list"));
const PartnerDetail = lazy(() => import("../pages/partner/detail"));

function PartnerRoutes() {
  return (
    <Switch>
      <Redirect path="/app/partner" to="/app/partner/list/" exact />
      <Route path="/app/partner/list/" component={PartnerList} exact />
      <Route
        path="/app/partner/list/details/:id"
        component={PartnerDetail}
        exact
      />
    </Switch>
  );
}

export default PartnerRoutes;
