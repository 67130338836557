import { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import styles from "./styles.module.scss";
import catPng from "assets/images/pre-login/new_cat.png";
import dogPng from "assets/images/pre-login/new_dog.png";
import homeIconPng from "assets/images/pre-login/home-icon.png";
import LoginButton from "components/Login/LoginButton";
import logoImg from "assets/images/logo/RoyalCanin.png";
import { useSearchParams } from "hooks/useSearchParams";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export default function PreLogin() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let location = useLocation();
  let search = useSearchParams();
  const Okta = useOktaAuth();
  const { t } = useTranslation("login");

  useEffect(() => {
    if (search?.toOkta === "staff") {
      redirect();
    }
  }, [location]);

  const redirect = () => {
    setLoading(true);
    Okta.oktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    if (Okta.authState?.isAuthenticated) {
      history.push("/app");
    }
  }, [Okta.authState]);

  return loading ? (
    <div className={styles["spinner-wrapper"]}>
      <Spin spinning={true} />
    </div>
  ) : (
    <Row className={styles["pre-login"]}>
      <Col
        className={classNames(styles["gutter-row"], styles["img-content"])}
        span={7}
      >
        <img
          className={classNames(
            styles.cat,
            styles["fl"],
            styles["img"],
            styles["positon-left"],
            styles["max-w-full"]
          )}
          src={catPng}
          alt=""
        />
      </Col>
      <Col
        className={classNames(
          styles["gutter-row"],
          styles["pre-login-content"]
        )}
        span={10}
      >
        <img src={logoImg} alt="RoyalCanin" className={styles.logo} />
        <h2>{t("welcome")}</h2>
        <div className={styles["pre-login-box"]}>
          <div>
            <img src={homeIconPng} alt="" />
          </div>
          <LoginButton handleLogin={() => setLoading(true)} />
        </div>
      </Col>
      <Col
        className={classNames(styles["gutter-row"], styles["img-content"])}
        span={7}
      >
        <img
          className={classNames(
            styles.dog,
            styles["img"],
            styles["positon-right"],
            styles["fr"],
            styles["max-w-full"]
          )}
          src={dogPng}
          alt=""
        />
      </Col>
    </Row>
  );
}
