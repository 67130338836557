import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// ImportFiles
const AllList = lazy(() => import("../pages/import-files/all-list"));
const MyList = lazy(() => import("../pages/import-files/my-list"));

function ImportFilesRoutes() {
  return (
    <Switch>
      <Redirect
        path="/app/import-files"
        to="/app/import-files/all-list"
        exact
      />
      <Route path="/app/import-files/all-list/" component={AllList} exact />
      <Route path="/app/import-files/my-list" component={MyList} exact />
    </Switch>
  );
}

export default ImportFilesRoutes;
