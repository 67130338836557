import { ReactNode } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

const Box = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>{children}</div>
  );
};

export default Box;
