/**
 * You can modify this file
 *
 * @version 6
 *
 */
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import i18n from "i18n/init";
import { logOut, OpenMessage } from "utils/tools";

const baseConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BFF_URI, // <--- Add your base url
  headers: {
    "Content-Encoding": "UTF-8",
    Accept: "application/json",
    "Content-Type": "application/json-patch+json",
  },
  paramsSerializer: {
    encode: (param) => param,
  },
};

const OktaErrors = [
  "INVALID_JWT_TOKEN_PARSE_ERROR",
  "INVALID_OKTA_TOKEN_EXPIRED",
  "INVALID_ID_TOKEN_EMPTY",
  "INVALID_ID_TOKEN_PARSE_ERROR",
  "INVALID_OKTA_TOKEN_PARSE_ERROR",
  "INVALID_OKTA_TOKEN_EMPTY",
  "INVALID_CLAIM_OKTATOKEN",
  "INVALID_CLAIM_COUNTRY",
  "INVALID_CLAIM_SCOPE",
  "INVALID_CLAIM_GROUPS",
  "INVALID_CLAIM_SCP",
  "INVALID_CLAIM_UID",
];

const msg: Record<string, string> = {
  "K-000002": "token expired",
  ...OktaErrors.reduce((acc, it) => {
    return { ...acc, [it]: i18n.t("common:token_expired") };
  }, {}),
};

let isErrorShowing = false;

let axiosInstance: AxiosInstance;

function getAxiosInstance(security: Security): AxiosInstance {
  if (!axiosInstance) {
    axiosInstance = Axios.create(baseConfig);

    // Response interceptor
    axiosInstance.interceptors.response.use(
      (async (response: AxiosResponse): Promise<SwaggerResponse<any>> => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        /**
         * Example on response manipulation
         *
         * @example
         *   const swaggerResponse: SwaggerResponse = {
         *     ...response,
         *   };
         *   return swaggerResponse;
         */
        return response.data;
      }) as any,
      (error: AxiosError<any>) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response) {
          if (
            !isErrorShowing ||
            OktaErrors.includes(error.response?.data.errorCode)
          ) {
            OpenMessage({
              success: false,
              message:
                msg[error.response.data.errorCode] ||
                msg[error.response.data.code] ||
                error.response.data.errorMessage ||
                i18n.t("common:request_failed"),
              handleAfterClose: () => {
                isErrorShowing = false;
                if (
                  ["K-000002", "K-000005", "K-000015"].includes(
                    error.response?.data.code
                  ) ||
                  OktaErrors.includes(error.response?.data.errorCode)
                ) {
                  logOut();
                }
              },
              isOktaError: OktaErrors.includes(error.response?.data.errorCode),
            });
          }
          isErrorShowing = true;
          return Promise.reject(
            new RequestError(
              error.response.data.errorMessage,
              error.response.status,
              error.response
            )
          );
        }

        if (error.isAxiosError) {
          return Promise.reject(new RequestError("noInternetConnection"));
        }
        return Promise.reject(error);
      }
    );
  }

  // ًًRequest interceptor
  axiosInstance.interceptors.request.use(
    async (requestConfig) => {
      // Do something before request is sent
      /** Example on how to add authorization based on security */
      if (security?.[0]) {
        const token =
          localStorage.getItem("token") ||
          localStorage.getItem("storeToken") ||
          "";
        requestConfig.headers!.Authorization = `Bearer ${token}`;
      }

      return requestConfig;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

class RequestError extends Error {
  constructor(
    public message: string,
    public status?: number,
    public response?: AxiosResponse
  ) {
    super(message);
  }

  isApiException = true;

  static isRequestError(error: any): error is RequestError {
    return error.isApiException;
  }
}

export type Security = any[] | undefined;

// export interface SwaggerResponse<R> extends AxiosResponse<R> {}
export type SwaggerResponse<R> = R;

export { getAxiosInstance, RequestError };
