export { ReactComponent as FlagAd } from "./ad.svg";
export { ReactComponent as FlagAe } from "./ae.svg";
export { ReactComponent as FlagAf } from "./af.svg";
export { ReactComponent as FlagAg } from "./ag.svg";
export { ReactComponent as FlagAi } from "./ai.svg";
export { ReactComponent as FlagAl } from "./al.svg";
export { ReactComponent as FlagAm } from "./am.svg";
export { ReactComponent as FlagAo } from "./ao.svg";
export { ReactComponent as FlagAq } from "./aq.svg";
export { ReactComponent as FlagAr } from "./ar.svg";
export { ReactComponent as FlagAs } from "./as.svg";
export { ReactComponent as FlagAt } from "./at.svg";
export { ReactComponent as FlagAu } from "./au.svg";
export { ReactComponent as FlagAw } from "./aw.svg";
export { ReactComponent as FlagAx } from "./ax.svg";
export { ReactComponent as FlagAz } from "./az.svg";
export { ReactComponent as FlagBa } from "./ba.svg";
export { ReactComponent as FlagBb } from "./bb.svg";
export { ReactComponent as FlagBd } from "./bd.svg";
export { ReactComponent as FlagBe } from "./be.svg";
export { ReactComponent as FlagBf } from "./bf.svg";
export { ReactComponent as FlagBg } from "./bg.svg";
export { ReactComponent as FlagBh } from "./bh.svg";
export { ReactComponent as FlagBi } from "./bi.svg";
export { ReactComponent as FlagBj } from "./bj.svg";
export { ReactComponent as FlagBl } from "./bl.svg";
export { ReactComponent as FlagBm } from "./bm.svg";
export { ReactComponent as FlagBn } from "./bn.svg";
export { ReactComponent as FlagBo } from "./bo.svg";
export { ReactComponent as FlagBq } from "./bq.svg";
export { ReactComponent as FlagBr } from "./br.svg";
export { ReactComponent as FlagBs } from "./bs.svg";
export { ReactComponent as FlagBt } from "./bt.svg";
export { ReactComponent as FlagBv } from "./bv.svg";
export { ReactComponent as FlagBw } from "./bw.svg";
export { ReactComponent as FlagBy } from "./by.svg";
export { ReactComponent as FlagBz } from "./bz.svg";
export { ReactComponent as FlagCa } from "./ca.svg";
export { ReactComponent as FlagCc } from "./cc.svg";
export { ReactComponent as FlagCd } from "./cd.svg";
export { ReactComponent as FlagCf } from "./cf.svg";
export { ReactComponent as FlagCg } from "./cg.svg";
export { ReactComponent as FlagCh } from "./ch.svg";
export { ReactComponent as FlagCi } from "./ci.svg";
export { ReactComponent as FlagCk } from "./ck.svg";
export { ReactComponent as FlagCl } from "./cl.svg";
export { ReactComponent as FlagCm } from "./cm.svg";
export { ReactComponent as FlagCn } from "./cn.svg";
export { ReactComponent as FlagCo } from "./co.svg";
export { ReactComponent as FlagCr } from "./cr.svg";
export { ReactComponent as FlagCu } from "./cu.svg";
export { ReactComponent as FlagCv } from "./cv.svg";
export { ReactComponent as FlagCw } from "./cw.svg";
export { ReactComponent as FlagCx } from "./cx.svg";
export { ReactComponent as FlagCy } from "./cy.svg";
export { ReactComponent as FlagCz } from "./cz.svg";
export { ReactComponent as FlagDe } from "./de.svg";
export { ReactComponent as FlagDj } from "./dj.svg";
export { ReactComponent as FlagDk } from "./dk.svg";
export { ReactComponent as FlagDm } from "./dm.svg";
export { ReactComponent as FlagDo } from "./do.svg";
export { ReactComponent as FlagDz } from "./dz.svg";
export { ReactComponent as FlagEc } from "./ec.svg";
export { ReactComponent as FlagEe } from "./ee.svg";
export { ReactComponent as FlagEg } from "./eg.svg";
export { ReactComponent as FlagEh } from "./eh.svg";
export { ReactComponent as FlagEr } from "./er.svg";
export { ReactComponent as FlagEs } from "./es.svg";
export { ReactComponent as FlagEt } from "./et.svg";
export { ReactComponent as FlagFi } from "./fi.svg";
export { ReactComponent as FlagFj } from "./fj.svg";
export { ReactComponent as FlagFk } from "./fk.svg";
export { ReactComponent as FlagFm } from "./fm.svg";
export { ReactComponent as FlagFo } from "./fo.svg";
export { ReactComponent as FlagFr } from "./fr.svg";
export { ReactComponent as FlagGa } from "./ga.svg";
export { ReactComponent as FlagGb } from "./gb.svg";
export { ReactComponent as FlagGd } from "./gd.svg";
export { ReactComponent as FlagGe } from "./ge.svg";
export { ReactComponent as FlagGf } from "./gf.svg";
export { ReactComponent as FlagGg } from "./gg.svg";
export { ReactComponent as FlagGh } from "./gh.svg";
export { ReactComponent as FlagGi } from "./gi.svg";
export { ReactComponent as FlagGl } from "./gl.svg";
export { ReactComponent as FlagGm } from "./gm.svg";
export { ReactComponent as FlagGn } from "./gn.svg";
export { ReactComponent as FlagGp } from "./gp.svg";
export { ReactComponent as FlagGq } from "./gq.svg";
export { ReactComponent as FlagGr } from "./gr.svg";
export { ReactComponent as FlagGs } from "./gs.svg";
export { ReactComponent as FlagGt } from "./gt.svg";
export { ReactComponent as FlagGu } from "./gu.svg";
export { ReactComponent as FlagGw } from "./gw.svg";
export { ReactComponent as FlagGy } from "./gy.svg";
export { ReactComponent as FlagHk } from "./hk.svg";
export { ReactComponent as FlagHm } from "./hm.svg";
export { ReactComponent as FlagHn } from "./hn.svg";
export { ReactComponent as FlagHr } from "./hr.svg";
export { ReactComponent as FlagHt } from "./ht.svg";
export { ReactComponent as FlagHu } from "./hu.svg";
export { ReactComponent as FlagId } from "./id.svg";
export { ReactComponent as FlagIe } from "./ie.svg";
export { ReactComponent as FlagIl } from "./il.svg";
export { ReactComponent as FlagIm } from "./im.svg";
export { ReactComponent as FlagIn } from "./in.svg";
export { ReactComponent as FlagIo } from "./io.svg";
export { ReactComponent as FlagIq } from "./iq.svg";
export { ReactComponent as FlagIr } from "./ir.svg";
export { ReactComponent as FlagIs } from "./is.svg";
export { ReactComponent as FlagIt } from "./it.svg";
export { ReactComponent as FlagJe } from "./je.svg";
export { ReactComponent as FlagJm } from "./jm.svg";
export { ReactComponent as FlagJo } from "./jo.svg";
export { ReactComponent as FlagJp } from "./jp.svg";
export { ReactComponent as FlagKe } from "./ke.svg";
export { ReactComponent as FlagKg } from "./kg.svg";
export { ReactComponent as FlagKh } from "./kh.svg";
export { ReactComponent as FlagKi } from "./ki.svg";
export { ReactComponent as FlagKm } from "./km.svg";
export { ReactComponent as FlagKn } from "./kn.svg";
export { ReactComponent as FlagKp } from "./kp.svg";
export { ReactComponent as FlagKr } from "./kr.svg";
export { ReactComponent as FlagKw } from "./kw.svg";
export { ReactComponent as FlagKy } from "./ky.svg";
export { ReactComponent as FlagKz } from "./kz.svg";
export { ReactComponent as FlagLa } from "./la.svg";
export { ReactComponent as FlagLb } from "./lb.svg";
export { ReactComponent as FlagLc } from "./lc.svg";
export { ReactComponent as FlagLi } from "./li.svg";
export { ReactComponent as FlagLk } from "./lk.svg";
export { ReactComponent as FlagLr } from "./lr.svg";
export { ReactComponent as FlagLs } from "./ls.svg";
export { ReactComponent as FlagLt } from "./lt.svg";
export { ReactComponent as FlagLu } from "./lu.svg";
export { ReactComponent as FlagLv } from "./lv.svg";
export { ReactComponent as FlagLy } from "./ly.svg";
export { ReactComponent as FlagMa } from "./ma.svg";
export { ReactComponent as FlagMc } from "./mc.svg";
export { ReactComponent as FlagMd } from "./md.svg";
export { ReactComponent as FlagMe } from "./me.svg";
export { ReactComponent as FlagMf } from "./mf.svg";
export { ReactComponent as FlagMg } from "./mg.svg";
export { ReactComponent as FlagMh } from "./mh.svg";
export { ReactComponent as FlagMk } from "./mk.svg";
export { ReactComponent as FlagMl } from "./ml.svg";
export { ReactComponent as FlagMm } from "./mm.svg";
export { ReactComponent as FlagMn } from "./mn.svg";
export { ReactComponent as FlagMo } from "./mo.svg";
export { ReactComponent as FlagMp } from "./mp.svg";
export { ReactComponent as FlagMq } from "./mq.svg";
export { ReactComponent as FlagMr } from "./mr.svg";
export { ReactComponent as FlagMs } from "./ms.svg";
export { ReactComponent as FlagMt } from "./mt.svg";
export { ReactComponent as FlagMu } from "./mu.svg";
export { ReactComponent as FlagMv } from "./mv.svg";
export { ReactComponent as FlagMw } from "./mw.svg";
export { ReactComponent as FlagMx } from "./mx.svg";
export { ReactComponent as FlagMy } from "./my.svg";
export { ReactComponent as FlagMz } from "./mz.svg";
export { ReactComponent as FlagNa } from "./na.svg";
export { ReactComponent as FlagNc } from "./nc.svg";
export { ReactComponent as FlagNe } from "./ne.svg";
export { ReactComponent as FlagNf } from "./nf.svg";
export { ReactComponent as FlagNg } from "./ng.svg";
export { ReactComponent as FlagNi } from "./ni.svg";
export { ReactComponent as FlagNl } from "./nl.svg";
export { ReactComponent as FlagNo } from "./no.svg";
export { ReactComponent as FlagNp } from "./np.svg";
export { ReactComponent as FlagNr } from "./nr.svg";
export { ReactComponent as FlagNu } from "./nu.svg";
export { ReactComponent as FlagNz } from "./nz.svg";
export { ReactComponent as FlagOm } from "./om.svg";
export { ReactComponent as FlagPa } from "./pa.svg";
export { ReactComponent as FlagPe } from "./pe.svg";
export { ReactComponent as FlagPf } from "./pf.svg";
export { ReactComponent as FlagPg } from "./pg.svg";
export { ReactComponent as FlagPh } from "./ph.svg";
export { ReactComponent as FlagPk } from "./pk.svg";
export { ReactComponent as FlagPl } from "./pl.svg";
export { ReactComponent as FlagPm } from "./pm.svg";
export { ReactComponent as FlagPn } from "./pn.svg";
export { ReactComponent as FlagPr } from "./pr.svg";
export { ReactComponent as FlagPs } from "./ps.svg";
export { ReactComponent as FlagPt } from "./pt.svg";
export { ReactComponent as FlagPw } from "./pw.svg";
export { ReactComponent as FlagPy } from "./py.svg";
export { ReactComponent as FlagQa } from "./qa.svg";
export { ReactComponent as FlagRe } from "./re.svg";
export { ReactComponent as FlagRo } from "./ro.svg";
export { ReactComponent as FlagRs } from "./rs.svg";
export { ReactComponent as FlagRu } from "./ru.svg";
export { ReactComponent as FlagRw } from "./rw.svg";
export { ReactComponent as FlagSa } from "./sa.svg";
export { ReactComponent as FlagSb } from "./sb.svg";
export { ReactComponent as FlagSc } from "./sc.svg";
export { ReactComponent as FlagSd } from "./sd.svg";
export { ReactComponent as FlagSe } from "./se.svg";
export { ReactComponent as FlagSg } from "./sg.svg";
export { ReactComponent as FlagSh } from "./sh.svg";
export { ReactComponent as FlagSi } from "./si.svg";
export { ReactComponent as FlagSj } from "./sj.svg";
export { ReactComponent as FlagSk } from "./sk.svg";
export { ReactComponent as FlagSl } from "./sl.svg";
export { ReactComponent as FlagSm } from "./sm.svg";
export { ReactComponent as FlagSn } from "./sn.svg";
export { ReactComponent as FlagSo } from "./so.svg";
export { ReactComponent as FlagSr } from "./sr.svg";
export { ReactComponent as FlagSs } from "./ss.svg";
export { ReactComponent as FlagSt } from "./st.svg";
export { ReactComponent as FlagSv } from "./sv.svg";
export { ReactComponent as FlagSx } from "./sx.svg";
export { ReactComponent as FlagSy } from "./sy.svg";
export { ReactComponent as FlagSz } from "./sz.svg";
export { ReactComponent as FlagTc } from "./tc.svg";
export { ReactComponent as FlagTd } from "./td.svg";
export { ReactComponent as FlagTf } from "./tf.svg";
export { ReactComponent as FlagTg } from "./tg.svg";
export { ReactComponent as FlagTh } from "./th.svg";
export { ReactComponent as FlagTj } from "./tj.svg";
export { ReactComponent as FlagTk } from "./tk.svg";
export { ReactComponent as FlagTl } from "./tl.svg";
export { ReactComponent as FlagTm } from "./tm.svg";
export { ReactComponent as FlagTn } from "./tn.svg";
export { ReactComponent as FlagTo } from "./to.svg";
export { ReactComponent as FlagTr } from "./tr.svg";
export { ReactComponent as FlagTt } from "./tt.svg";
export { ReactComponent as FlagTv } from "./tv.svg";
export { ReactComponent as FlagTw } from "./tw.svg";
export { ReactComponent as FlagTz } from "./tz.svg";
export { ReactComponent as FlagUa } from "./ua.svg";
export { ReactComponent as FlagUg } from "./ug.svg";
export { ReactComponent as FlagUm } from "./um.svg";
export { ReactComponent as FlagUs } from "./us.svg";
export { ReactComponent as FlagUy } from "./uy.svg";
export { ReactComponent as FlagUz } from "./uz.svg";
export { ReactComponent as FlagVa } from "./va.svg";
export { ReactComponent as FlagVc } from "./vc.svg";
export { ReactComponent as FlagVe } from "./ve.svg";
export { ReactComponent as FlagVg } from "./vg.svg";
export { ReactComponent as FlagVi } from "./vi.svg";
export { ReactComponent as FlagVn } from "./vn.svg";
export { ReactComponent as FlagVu } from "./vu.svg";
export { ReactComponent as FlagWf } from "./wf.svg";
export { ReactComponent as FlagWs } from "./ws.svg";
export { ReactComponent as FlagXk } from "./xk.svg";
export { ReactComponent as FlagXx } from "./xx.svg";
export { ReactComponent as FlagYe } from "./ye.svg";
export { ReactComponent as FlagYt } from "./yt.svg";
export { ReactComponent as FlagZa } from "./za.svg";
export { ReactComponent as FlagZm } from "./zm.svg";
export { ReactComponent as FlagZw } from "./zw.svg";

export const FlagsList = [
  "FlagAd",
  "FlagAe",
  "FlagAf",
  "FlagAg",
  "FlagAi",
  "FlagAl",
  "FlagAm",
  "FlagAo",
  "FlagAq",
  "FlagAr",
  "FlagAs",
  "FlagAt",
  "FlagAu",
  "FlagAw",
  "FlagAx",
  "FlagAz",
  "FlagBa",
  "FlagBb",
  "FlagBd",
  "FlagBe",
  "FlagBf",
  "FlagBg",
  "FlagBh",
  "FlagBi",
  "FlagBj",
  "FlagBl",
  "FlagBm",
  "FlagBn",
  "FlagBo",
  "FlagBq",
  "FlagBr",
  "FlagBs",
  "FlagBt",
  "FlagBv",
  "FlagBw",
  "FlagBy",
  "FlagBz",
  "FlagCa",
  "FlagCc",
  "FlagCd",
  "FlagCf",
  "FlagCg",
  "FlagCh",
  "FlagCi",
  "FlagCk",
  "FlagCl",
  "FlagCm",
  "FlagCn",
  "FlagCo",
  "FlagCr",
  "FlagCu",
  "FlagCv",
  "FlagCw",
  "FlagCx",
  "FlagCy",
  "FlagCz",
  "FlagDe",
  "FlagDj",
  "FlagDk",
  "FlagDm",
  "FlagDo",
  "FlagDz",
  "FlagEc",
  "FlagEe",
  "FlagEg",
  "FlagEh",
  "FlagEr",
  "FlagEs",
  "FlagEt",
  "FlagFi",
  "FlagFj",
  "FlagFk",
  "FlagFm",
  "FlagFo",
  "FlagFr",
  "FlagGa",
  "FlagGb",
  "FlagGd",
  "FlagGe",
  "FlagGf",
  "FlagGg",
  "FlagGh",
  "FlagGi",
  "FlagGl",
  "FlagGm",
  "FlagGn",
  "FlagGp",
  "FlagGq",
  "FlagGr",
  "FlagGs",
  "FlagGt",
  "FlagGu",
  "FlagGw",
  "FlagGy",
  "FlagHk",
  "FlagHm",
  "FlagHn",
  "FlagHr",
  "FlagHt",
  "FlagHu",
  "FlagId",
  "FlagIe",
  "FlagIl",
  "FlagIm",
  "FlagIn",
  "FlagIo",
  "FlagIq",
  "FlagIr",
  "FlagIs",
  "FlagIt",
  "FlagJe",
  "FlagJm",
  "FlagJo",
  "FlagJp",
  "FlagKe",
  "FlagKg",
  "FlagKh",
  "FlagKi",
  "FlagKm",
  "FlagKn",
  "FlagKp",
  "FlagKr",
  "FlagKw",
  "FlagKy",
  "FlagKz",
  "FlagLa",
  "FlagLb",
  "FlagLc",
  "FlagLi",
  "FlagLk",
  "FlagLr",
  "FlagLs",
  "FlagLt",
  "FlagLu",
  "FlagLv",
  "FlagLy",
  "FlagMa",
  "FlagMc",
  "FlagMd",
  "FlagMe",
  "FlagMf",
  "FlagMg",
  "FlagMh",
  "FlagMk",
  "FlagMl",
  "FlagMm",
  "FlagMn",
  "FlagMo",
  "FlagMp",
  "FlagMq",
  "FlagMr",
  "FlagMs",
  "FlagMt",
  "FlagMu",
  "FlagMv",
  "FlagMw",
  "FlagMx",
  "FlagMy",
  "FlagMz",
  "FlagNa",
  "FlagNc",
  "FlagNe",
  "FlagNf",
  "FlagNg",
  "FlagNi",
  "FlagNl",
  "FlagNo",
  "FlagNp",
  "FlagNr",
  "FlagNu",
  "FlagNz",
  "FlagOm",
  "FlagPa",
  "FlagPe",
  "FlagPf",
  "FlagPg",
  "FlagPh",
  "FlagPk",
  "FlagPl",
  "FlagPm",
  "FlagPn",
  "FlagPr",
  "FlagPs",
  "FlagPt",
  "FlagPw",
  "FlagPy",
  "FlagQa",
  "FlagRe",
  "FlagRo",
  "FlagRs",
  "FlagRu",
  "FlagRw",
  "FlagSa",
  "FlagSb",
  "FlagSc",
  "FlagSd",
  "FlagSe",
  "FlagSg",
  "FlagSh",
  "FlagSi",
  "FlagSj",
  "FlagSk",
  "FlagSl",
  "FlagSm",
  "FlagSn",
  "FlagSo",
  "FlagSr",
  "FlagSs",
  "FlagSt",
  "FlagSv",
  "FlagSx",
  "FlagSy",
  "FlagSz",
  "FlagTc",
  "FlagTd",
  "FlagTf",
  "FlagTg",
  "FlagTh",
  "FlagTj",
  "FlagTk",
  "FlagTl",
  "FlagTm",
  "FlagTn",
  "FlagTo",
  "FlagTr",
  "FlagTt",
  "FlagTv",
  "FlagTw",
  "FlagTz",
  "FlagUa",
  "FlagUg",
  "FlagUm",
  "FlagUs",
  "FlagUy",
  "FlagUz",
  "FlagVa",
  "FlagVc",
  "FlagVe",
  "FlagVg",
  "FlagVi",
  "FlagVn",
  "FlagVu",
  "FlagWf",
  "FlagWs",
  "FlagXk",
  "FlagXx",
  "FlagYe",
  "FlagYt",
  "FlagZa",
  "FlagZm",
  "FlagZw",
] as const;

export type FlagIcon = typeof FlagsList[number];
