import { useEffect } from "react";
import { Spin } from "antd";
import styles from "./styles.module.scss";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router";

export default function Logout() {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();
  const LogoutFunction = () => {
    localStorage.removeItem("token");
    const basename = sessionStorage.getItem("basename") || "/";
    const redirectUri = window.location.origin + basename;
    const idToken = authState?.idToken?.idToken;
    if (idToken) {
      window.location.href = `${
        process.env.REACT_APP_ISSUER
      }/v1/logout?id_token_hint=${
        idToken ? idToken : ""
      }&post_logout_redirect_uri=${redirectUri}`;
      localStorage.clear();
    } else if (authState && !authState?.isAuthenticated) {
      localStorage.clear();
      history.push("/");
    }
  };
  useEffect(() => {
    LogoutFunction();
  });

  return (
    <div className={styles.wrapper}>
      <Spin spinning={true} className={styles.spinner} />
    </div>
  );
}
